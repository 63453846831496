import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import styles from './SignUp.module.css';

import { useSelector } from 'react-redux';

const SignUpTerms = ({ history }) => {
  const { t } = useTranslation();
  const [policyChecked, setPolicyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const app = useSelector((state) => state.app)
  const handlePolicyCheck = () => {
    setPolicyChecked(!policyChecked);
  };

  const handleTermsCheck = () => {
    setTermsChecked(!termsChecked);
  };

  const handleValidation = () => {
    return policyChecked && termsChecked;
  };

  return (
    <>
      <Header />
      <ContentApp>
        <div className='font-medium bold text-white terms-and-conditions'>{t('terms.title')}</div>
        <div className='text-white bold mt-5'>{t('privacyStatement')}</div>
        <div className='text-secondary font-tiny mt-1'>
          {t('terms.description1')}{' '}
          <u className='text-primary' onClick={() => history.push('/privacy-policy')}>
            {t('privacyStatement')}.
          </u>
          <div className='mt-4 row'>
            <label className='checkbox-container ml-3 '>
              <input
                type='checkbox'
                style={{ width: '20px', height: '20px'}}
                checked={policyChecked}
                onChange={handlePolicyCheck}
              />
              <span className='checkmark' style={{ marginLeft: '' }}></span>
              <div className='col ml-3'>{t('terms.acceptTerms1')}</div>
            </label>
           
            {/* <div className=' error-label text-white ml-2 mt-4' style={{ width: '90%' }}>
            Por favor, acepta los terminos para continuar
          </div> */}
          </div>
        </div>
        <div className='text-white bold mt-4'>{t('termsAndConditions')}</div>
        <div className='text-secondary font-tiny mt-1'>

          {t('')}
          {t('terms.description2', {
                      organization: app.titleWeb ,
                    })}

          
          {' '}
          <u className='text-primary' onClick={() => history.push('/terms-and-conditions')}>
            {t('termsAndConditions')}.
          </u>
          <div className='mt-4 row'>
            <label className='checkbox-container ml-3'>
              <input
                type='checkbox'
                style={{ width: '20px', height: '20px' }}
                checked={termsChecked}
                onChange={handleTermsCheck}
              />
              <span className='checkmark' style={{ marginLeft: '' }}></span>
              <div className='col ml-3'>{t('terms.acceptTerms2')}</div>
            </label>
           
          </div>
        </div>
        <Button
          className={`${styles.btn} mt-5`}
          size='large'
          text={t('continue')}
          iconColor='#000'
          icon
          typeIcon='arrow'
          onClick={() => history.push('/sign-up')}
          disabled={!handleValidation()}
        />
      </ContentApp>
    </>
  );
};

export default SignUpTerms;
