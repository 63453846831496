import React, { useState, useEffect } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import Button from '../../../../components/Common/Button/Button';
import ScanIdScreen from '../ScanScreen/ScanIdScreen';
import { useTranslation } from 'react-i18next';
import FixedButton from '../../../../components/Common/FixedButton/FixedButton';

const BackValidateKyc = ({ history }) => {
  const { t } = useTranslation();
  const [backImage, setBackImage] = useState(null);
  const handleCaptureFace = (img) => {
    setBackImage(img);
  };

  useEffect(() => {
    if (history.location.state === undefined) {
      history.push('/kyc-validate-front');
    }
  }, []);

  const handleConfirmFace = () => {
    if (backImage) {
      history.push({
        pathname: '/kyc-validate-selfie',
        state: { backImage: backImage, frontImage: history.location.state.frontImage },
      });
    }
  };
  const handleReset = () => {
    setBackImage(null);
  };

  /******** ESTO NO VA *********/
  const onChange = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };

  const onLoad = (fileString) => {
    history.push({
      pathname: '/kyc-validate-selfie',
      state: { backImage: fileString, frontImage: history.location.state.frontImage },
    });
  };

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  /******** ESTO NO VA *********/

  return (
    <>
      <Header />
      <ContentApp background={true}>
        <div>
          {backImage ? (
            <div className='container p-0 text-center'>
              <div className='font-medium bold' style={{ color: '#fff' }}>
                {t('kyc.confirmPicture')}
              </div>
              <div className='m-2 text-secondary'>{t('kyc.confirmText')}</div>
              <div className='pt-5'>
                <img
                  src={backImage}
                  style={{ height: '250px', width: '100%' }}
                  alt='Captured face'
                />
                <div className='face'></div>
              </div>
              <div className='text-center'>
                <div
                  onClick={handleReset}
                  className='mt-4'
                  style={{ color: 'var(--color-primary)' }}
                >
                  {t('kyc.tryAgain')}
                </div>
                <FixedButton>
                  <Button
                    text={t('confirm')}
                    size='large'
                    onClick={handleConfirmFace}
                    fontSize={'17px'}
                    icon={true}
                    typeIcon='arrow'
                    iconColor='#000'
                    className='btn-primary'
                  />
                </FixedButton>
              </div>
            </div>
          ) : (
            <>
              {process.env.REACT_KYC_TESTING ? (
                <input type='file' onChange={onChange} />
              ) : (
                <ScanIdScreen position='back' onCaptureDocument={handleCaptureFace} />
              )}
            </>
          )}
        </div>
      </ContentApp>
    </>
  );
};
export default BackValidateKyc;
