import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useGenericTranslation = () => {
  const { t } = useTranslation();
  const templateNameId = useSelector((state) => state.app.templateNameId);

  const getClientTranslation = (key) => {
    return t(`genericWords.${templateNameId}.${key}`);
  };

  return { getClientTranslation };
};

export default useGenericTranslation;
