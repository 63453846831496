import React from 'react';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../helpers/dates';
import Money from '../../Common/Money/Money';
import { useTranslation } from 'react-i18next';
import Badge from '../../Badge/Badge';

const ActivityItem = ({ index, activity, handleClick, date, rightText, className }) => {
  const { t } = useTranslation();
  const isTeamLead = useSelector((state) => state.associates.roles.includes('salesteamlead'));
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  const getStatus = (reservationStatus, reservationCommission, commission) => {
    switch (reservationStatus) {
      case 'PENDING':
        return <Badge text={t('reservationStatus.pending')} type={'pending'} />;
      case 'WAITING_DEPOSIT':
        return <Badge text={t('reservationStatus.pendingPayment')} type={'pending'} />;
      case 'CONFIRMED':
      case 'CHECKIN':
        return <Badge text={t('reservationStatus.confirmed')} type={'active'} />;
      case 'CANCELLED':
        return <Badge text={t('reservationStatus.cancelled')} type={'cancelled'} />;
      case 'EXPIRED':
        return <Badge text={t('reservationStatus.expired')} type={'expired'} />;
      case 'REJECTED':
        return <Badge text={t('reservationStatus.rejected')} type={'cancelled'} />;
      case 'NO_SHOW':
      case 'NO_SHOW_MANUAL':
        return <Badge text={t('reservationStatus.noshow')} type={'expired'} />;
      default:
        return getStatusByCommission(reservationCommission, commission);
    }
  };

  const getStatusByCommission = (reservationCommission, commission) => {
    switch (reservationCommission) {
      case 'DISPUTED':
        return <Badge text={t('reservationStatus.underReview')} type={'cancelled'} />;
      case 'PENDING':
      case 'PENDING_REVIEW':
        return <Money value={commission} decimal={false} lowDecimals symbolSize='.85rem' />;
      case 'REJECTED':
        return <Badge text={t('reservationStatus.rejected')} type={'cancelled'} />;
      case 'APPROVED':
        return <Money value={commission} decimal={false} lowDecimals symbolSize='.85rem' />;
      case 'ACCREDITED':
        return <Money value={commission} decimal={false} lowDecimals symbolSize='.85rem' />;
    }
  };

  return (
    <div className={`row text-white ${className}`} key={index} onClick={handleClick}>
      <div className='col'>
        <div className='bold'>{activity?.showName}</div>
        <div className='font-small'>
          {(activity?.customerName || activity?.paxs) && (
            <div className='text-truncate text-secondary'>
              {activity?.customerName} x {activity.paxs}
            </div>
          )}

          <div className='text-secondary line-height-1'>

            {format(parseISO(activity.reservationLocalDate), 'PP HH:mm', {
              locale,
            })}
          </div>
          {!activity.isOwnSale && (
            <div style={{ color: 'var(--color-primary)' }} className='font-extra-small'>
              {activity.sellerName ? `#${activity.sellerName}` : ''}{' '}
              {activity.sellerNickName ? `(${activity.sellerNickName})` : ''}
            </div>
          )}
        </div>
      </div>
      <div className='col-auto my-auto'>
        {getStatus(
          activity.status,
          activity.commissionStatus,
          isTeamLead ? activity.sellerCommission : activity.commission,
        )}
      </div>
    </div>
  );
};

export default ActivityItem;
