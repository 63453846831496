import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRegisterCompleted } from '../../../../actions/associatesActions';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Common/Header/Header';
import SuccessScreen from '../../../Common/SuccessScreen';
import ErrorScreen from '../../../Common/ErrorScreen';

const FinishKyc = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = history.location;
  const response = state && state.response ? state.response : null;
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    description: '',
  });

  useEffect(() => {
    if(response === null) {
      history.push('/');
      return
    }

    if (!response.data?.success && response?.data?.errors) {
      dispatch(setRegisterCompleted(true));

      response.data.errors.forEach((error) => {
        const title = t(`kycError.${error}.title`);
        const description = t(`kycError.${error}.description`);

        setErrorMessage({
          title,
          description,
        });
      });
    }
  }, []);

  if(response === null) {
    return null;
  }

  return (
    <>
      {response.data.success ? (
        <>
          <Header showBack={false}/>
          <SuccessScreen title={`${t('done')}!`} subtitle={t('kyc.successKyc')} path={'/'} />
        </>
      ) : (
        <>
          <Header title={t('kyc.validateIdentity')} hasBackground={true} />
          <ErrorScreen
            title={errorMessage.title}
            subtitle={errorMessage.description}
            buttonText={t('tryAgain')}
            path={'/kyc-start'}
          />
        </>
      )}
    </>
  );
};

export default FinishKyc;
