import React from 'react';
import ChevronIcon from '../../icons/chevronIcon';
import styles from './button.module.css';
import QrIcon from '../../icons/QrIcon';
import LinkIcon from '../../icons/LinkIcon';
const Button = ({
  text,
  size,
  icon = false,
  iconColor = '#fff',
  typeIcon = null,
  type = 'submit',
  onClick,
  className,
  disabled = false,
  dataDismiss,
  ariaLabel,
  width = null,
  isClicked = false,
  isSelected = false,
  hasBorder = false,
  color = '#000',
  backgroundColor = 'var(--color-primary)',
}) => {
  const sizeConfig = {
    large: {
      widthConfig: '100%',
      height: '3.8rem',
    },
    normal: {
      widthConfig: '100%',
      height: '3.2rem',
      fontSizeConfig: '1rem',
    },
    medium: {
      widthConfig: `${width}`,
      height: '45px',
      fontSizeConfig: '1rem',
    },
    small: {
      widthConfig: `${width}`,
      height: '35px',
      fontSizeConfig: '0.9rem',
    },
    card: {
      widthConfig: `${width}`,
      height: '70px',
      fontSizeConfig: '0.6rem',
    },
     custom: {
      widthConfig: '80%',
      height: '3rem',
    },

  };

  const { height, widthConfig, fontSizeConfig } = sizeConfig[size];

  const buttonStyles = {
    width: widthConfig,
    height,
    fontSize: fontSizeConfig,
    color: color,
    backgroundColor: backgroundColor,
  };

  return (
    <button
      style={buttonStyles}
      className={`${styles.buttonWellet} fw-extrabold ${className ? className : null}  ${
        isSelected ? `${styles.btnSelected}` : isClicked ? styles.btnClickeable : ''
      } ${disabled && styles.disabledOpacity} ${
        hasBorder ? styles.border : ''
      } d-flex align-items-center justify-content-center position-relative ${
        typeIcon === 'add' ? 'justify-content-between' : ''
      }`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-dismiss={dataDismiss}
      aria-label={ariaLabel}
    >
      <div className={typeIcon === 'add' ? styles.textAdd : styles.text}>{text}</div>
      {icon && typeIcon === 'arrow' && (
        <span className='ml-3 btn-arrow'>
          <ChevronIcon size={20} fillColor={iconColor} />
        </span>
      )}
      {icon && typeIcon === 'qr' && (
        <span className='ml-3'>
          <QrIcon size={20} fillColor={iconColor} />
        </span>
      )}
      {icon && typeIcon === 'link' && (
        <span className='ml-3'>
          <LinkIcon size={20} fillColor={iconColor} />
        </span>
      )}
      {icon && (typeIcon === 'add' || typeIcon === 'addCampaigns') && (
        <div
          className={`${
            typeIcon === 'add' ? styles.add : typeIcon === 'addCampaigns' ? styles.addCampaigns : ''
          }`}
        >
          +
        </div>
      )}
    </button>
  );
};

export default Button;
