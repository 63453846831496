import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './LinkRestaurant.module.css';
import { WelletContext } from '../../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import Line from '../../../../components/Common/Line/Line';
import ShareIcon from '../../../../components/icons/ShareIcon';
import { Navigation, Pagination } from 'swiper/modules';
import { Skeleton } from '@mui/material';
import TriangleArrowIcon from '../../../../components/icons/TriangleArrowIcon';

const LinkQrRestaurant = () => {
  const { t } = useTranslation();
  const swiperRef = useRef(null);

  const language = useSelector((state) => state.app.language);
  const welletContext = useContext(WelletContext);
  const [loading, setLoading] = useState(true);
  const [showSelected, setShowSelected] = useState(null);
  const [shows, setShows] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);

  const getShows = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`/shows/getAll?lang=${language}`);
      setShows(result.data);
      setShowSelected(result.data[0]);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getShows();
  }, []);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: showSelected.name,
          url: showSelected.link,
        });
      } else {
        console.warn('Web Share API not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.activeIndex;

    setShowSelected(shows[currentIndex]);
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <ContentApp>
          <Swiper
            spaceBetween={30}
            onSlideChange={handleSlideChange}
            modules={[Pagination]}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            className='mySwiper'
          >
            {shows.map((show) => (
              <SwiperSlide key={show.id}>
                <div className='card align-items-center p-3'>
                  <div className={styles.imageContainer}>
                    <img src={show.logoUrl} alt='logo' />
                  </div>
                  <div className='font-large fw-semibold my-2 text-center'>{show.name}</div>
                  <div className='text-secondary '>{show.organization.city}</div>
                  <Line color='#353535' className='w-100 my-4' />
                  <div style={{ position: 'relative', height: '292px', width: '292px' }}>
                    {loadingImage && (
                      <Skeleton
                        sx={{
                          bgcolor: 'grey.900',
                          borderRadius: '5px',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                        variant='rectangular'
                      />
                    )}
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=292x292&bgcolor=FFFFFF&color=000&format=png&data=${show.link}`}
                      alt={'QR'}
                      style={{ display: loadingImage ? 'none' : 'block' }}
                      onLoad={() => setLoadingImage(false)}
                    />
                  </div>
                  <div className='font-large text-center fw-semibold m-3'>
                    {t('scanToMakeReservation')}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className='text-center text-secondary font-small mt-3'
            onClick={() => handleShare(showSelected)}
          >
            {t('shareLinkToBook')}
            <ShareIcon size={25} color={'#7e7e7e'} styles={{ marginBottom: '4px' }} />
          </div>
        </ContentApp>
      )}
    </>
  );
};

export default LinkQrRestaurant;
