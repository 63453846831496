const initialState = {
  authChecked: false,
  authenticated: false,
  user: null,
  isAssociate: false,
  roles: [],
  jwt: '',
  showCommission: true,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'ASSOCIATES_AUTH_CHECKED':
      return {
        ...state,
        authChecked: true,
      };
    case 'FIRST_LOGIN':
      return {
        ...state,
        user: { ...state.user, firstLogin: true },
      };
    case 'ASSOCIATES_AUTHENTICATED':
      return {
        ...state,
        authenticated: true,
        jwt: action.jwt,
        roles: Array.isArray(action.userRoles) ? action.userRoles : [action.userRoles],
      };
      case 'NICK_NAME':
        return {
          ...state,
        user: { ...state.user, nickName:action.payload },
        };
    case 'ASSOCIATES_INFO':
      return {
        ...state,
        authChecked: true,
        user: action.payload,
      };
    case 'ASSOCIATES_PROFILE_IMAGE':
      return {
        ...state,
        user: { ...state.user, profileImage: action.payload },
      };
    case 'ASSOCIATES_TOGGLE_COMMISSION':
      return {
        ...state,
        showCommission: !state.showCommission,
      };
    case 'ASSOCIATES_LOGOUT':
      return {
        ...state,
        authenticated: false,
        user: null,
        jwt: '',
        roles: [],
        showCommission: false,
      };

    case 'WITHDRAW_READY':
      return {
        ...state,
        withdraw: { status: 'READY', error: null },
      };
    case 'WITHDRAW_PROCESSING':
      return {
        ...state,
        withdraw: { status: 'PROCESSING', error: null },
      };
    case 'WITHDRAW_SUCCESS':
      return {
        ...state,
        withdraw: { status: 'SUCCESS', error: null, operationId: action.payload.operationNumber },
      };
    case 'WITHDRAW_ERROR':
      return {
        ...state,
        withdraw: { status: 'ERROR', error: action.payload },
      };
    case 'USER_WITHDRAWAL':
      return {
        ...state,
        user: { ...state.user, userWithdrawalMethod: action.payload },
      };
    case 'STATUS_ACCOUNT':
      return {
        ...state,
        user: { ...state.user, salesCategory: action.payload },
      };
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          registerCompleted: action.payload,
        },
      };
    case 'MARK_NOTIFICATION_AS_READ':
      return {
        ...state,
        user: {
          ...state.user,
          notificationsToRead: Math.max(0, state.user.notificationsToRead - 1),
        },
      };
    default:
      return state;
  }
};
