import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getQueryString } from '../../../helpers/url';
import { exchangeToken } from '../../../actions/associatesActions';
import { setCookie, getCookie, deleteCookie } from '../../../helpers/cookie';
import { useTranslation } from 'react-i18next';
import useForm from '../../../helpers/useForm';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import Button from '../../../components/Common/Button/Button';
import { Spinner } from '../../../components/Common/Spinner/Spinner';
import { Countdown } from '../../../components/Common/Countdown/Countdown';
import { useSelector } from 'react-redux';
import InputFormBorder from '../../../components/Common/InputFormBorder/InputFormBorder';
const LoginPassword = () => {
  const { t } = useTranslation();
  const [errorLogin, setErrorLogin] = useState(undefined);
  const [verifyingUser, setVerifyingUser] = useState(false);
  const [isCountdownFinish, setIsCountdownFinish] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const auth = getAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const email = getQueryString('email');
  const app = useSelector((state) => state.app);
  const onLoginSuccess = (user) => {
    exchangeToken(dispatch, user, () => history.push('/'));
  };

  const onClickChangeHandle = () => {
    history.push({
      pathname: '/login-user',
    });
  };

  const onForgotPasswordHandle = () => {
    history.push({
      pathname: '/forgot-password',
      search: '?email=' + email,
    });
  };
  function validate(values) {
    setErrorLogin(undefined);
    let errors = {};

    if (!values.password) {
      errors.password = t('passwordError.PASSWORD_EMPTY');
    }

    return errors;
  }

  const onClickSignIn = () => {
    setVerifyingUser(true);

    signInWithEmailAndPassword(auth, email, values.password)
      .then(async (userCredential) => {
        onLoginSuccess(userCredential.user);
      })

      .catch((error) => {
        switch (error.code) {
          case 'auth/wrong-password':
            setAttempts(attempts + 1);
            setErrorLogin(t('passwordError.PASSWORD_WRONG'));
            break;
          case 'auth/user-disabled':
            setErrorLogin(t('emailError.USER_SUSPENDED'));
            setAttempts(attempts + 1);
            break;
          default:
            setAttempts(attempts + 1);
            setErrorLogin(t('emailError.DEFAULT'));
            break;
        }

        setVerifyingUser(false);
        if (onLoginError) onLoginError(error.code);
      });
  };
  const restartAttempts = () => {
    setIsCountdownFinish(true);
    setShowCounter(false);
    deleteCookie('blockAccount');
  };

  const onLoginError = (error) => {
    console.error(error);
  };

  useEffect(() => {
    if (attempts >= 5) {
      setCookie('blockAccount', true, 3);
      setIsCountdownFinish(false);
      setShowCounter(true);
      setAttempts(0);
    }
  }, [attempts]);

  const { values, errors, handleChange, handleSubmit } = useForm(onClickSignIn, validate);

  const inputName = (
    <InputFormBorder
      name={'password'}
      type={'password'}
      label={t('login.password')}
      placeholder={t('password')}
      value={values.password}
      error={errors.password || errorLogin}
      onChange={handleChange}
    />
  );
  // Timer
  let today = new Date();
  today.setTime(today.getTime() + 3 * 60 * 1000);

  const isAccountBlocked = getCookie('blockAccount');
  return (
    <>
      <Header title={''} pathName={'/login-user'} hasBackground={true} />
      <ContentApp background={true}>
        <div className=''>
          <form onSubmit={handleSubmit}>
            <div
              className='login-title bold font-medium'
              style={{ color: 'var(--color-font-primary)' }}
            >
              {t('login.enterPassword', {
                organization: app.titleWeb,
              })}
            </div>
            <div className='mt-5 '>{inputName}</div>
            <div className='mt-5 d-flex flex-column align-items-center'>
              <Button
                disabled={isAccountBlocked}
                size='large'
                className='btn-primary'
                text={
                  verifyingUser ? (
                    <div className='d-flex justify-content-center'>
                      <Spinner size={25} color='#000' />
                    </div>
                  ) : (
                    t('login.signIn')
                  )
                }
                hasBorder={true}
                icon={verifyingUser ? false : true}
                iconColor='#000'
                typeIcon='arrow'
              />
              <div
                className='text-primary font-tiny text-center mt-5'
                onClick={onForgotPasswordHandle}
              >
                {t('login.forgotPassword')}
              </div>
            </div>
          </form>
        </div>

        {isAccountBlocked && (
          <div className='mt-5 text-center' style={{ color: 'var(--color-font-gray)' }}>
            {t('login.maximumAttempts')}:{' '}
            <Countdown initialDate={today.toISOString()} onCountdownFinish={restartAttempts} />
          </div>
        )}
      </ContentApp>
    </>
  );
};

export default LoginPassword;
