import React from 'react';

const InstagramIcon2 = ({ size = 24, color = '#000', strokeWidth = 0.4 }) => {
  return (
    <svg
      id="b"
      data-name="Capa_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.17 20.17"
      width={size}
      height={size}
      stroke={color} 
      strokeWidth={strokeWidth} 
    >
      <g id="c" data-name="Capa_1">
        <g>
          <path
            d="M5.48,19.25c-1.16,0-2.33-.44-3.21-1.33-.86-.86-1.33-2-1.33-3.21s.47-2.35,1.33-3.21l3.23-3.23c.86-.86,2-1.33,3.21-1.33s2.35.47,3.21,1.33l.33.33c.27.27.27.72,0,.99s-.72.27-.99,0l-.33-.33c-1.18-1.19-3.26-1.19-4.44,0l-3.23,3.23c-.59.59-.92,1.38-.92,2.22s.33,1.63.92,2.22c1.22,1.22,3.22,1.23,4.44,0l1.7-1.7c.27-.27.72-.27.99,0s.27.72,0,.99l-1.7,1.7c-.89.88-2.05,1.33-3.21,1.33Z"
          />
          <path
            d="M11.47,13.26c-1.16,0-2.33-.44-3.21-1.33-.27-.27-.27-.72,0-.99s.72-.27.99,0c1.23,1.23,3.22,1.22,4.44,0l3.23-3.23c1.22-1.22,1.22-3.22,0-4.44-1.18-1.19-3.26-1.19-4.44,0l-1.61,1.61c-.27.27-.72.27-.99,0s-.27-.72,0-.99l1.61-1.61c.86-.86,2-1.33,3.21-1.33s2.35.47,3.21,1.33c1.77,1.77,1.77,4.65,0,6.42l-3.23,3.23c-.89.88-2.05,1.33-3.21,1.33Z"
          />
          <path
            d="M4.81,5.51c-.18,0-.36-.07-.5-.21l-2.05-2.05c-.27-.27-.27-.72,0-.99s.72-.27.99,0l2.05,2.05c.27.27.27.72,0,.99-.14.14-.32.21-.5.21Z"
          />
          <path
            d="M7.46,3.03c-.39,0-.7-.31-.7-.7V.7c0-.39.31-.7.7-.7s.7.31.7.7v1.62c0,.39-.31.7-.7.7Z"
          />
          <path
            d="M2.33,8.16H.7c-.39,0-.7-.31-.7-.7s.31-.7.7-.7h1.62c.39,0,.7.31.7.7s-.31.7-.7.7Z"
          />
          <path
            d="M17.41,18.11c-.18,0-.36-.07-.5-.21l-2.05-2.05c-.27-.27-.27-.72,0-.99s.72-.27.99,0l2.05,2.05c.27.27.27.72,0,.99-.14.14-.32.21-.5.21Z"
          />
          <path
            d="M12.71,20.17c-.39,0-.7-.31-.7-.7v-1.62c0-.39.31-.7.7-.7s.7.31.7.7v1.62c0,.39-.31.7-.7.7Z"
          />
          <path
            d="M19.47,13.41h-1.62c-.39,0-.7-.31-.7-.7s.31-.7.7-.7h1.62c.39,0,.7.31.7.7s-.31.7-.7.7Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default InstagramIcon2;
