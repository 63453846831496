import React from 'react';

const ShareIcon = ({ color, size, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
      style={styles}
    >
      <g
        transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M2215 4200 l-340 -340 73 -72 72 -73 220 220 220 220 0 -953 0 -952 100 0 100 0 0 952 0 953 220 -220 220 -220 72 73 73 72 -340 340 c-187 187 -342 340 -345 340 -3 0 -158 -153 -345 -340z' />
        <path d='M1444 3469 c-86 -25 -181 -122 -204 -209 -7 -26 -9 -370 -8 -1027 l3 -988 23 -46 c31 -63 86 -117 152 -148 l55 -26 1095 0 1095 0 56 26 c66 31 120 84 151 148 l23 46 0 1010 0 1010 -23 42 c-34 64 -80 111 -136 141 l-51 27 -402 3 -403 3 0 -101 0 -100 360 0 c294 0 367 -3 396 -15 68 -28 65 20 62 -1028 -3 -1018 -1 -971 -55 -996 -35 -16 -2113 -15 -2147 0 -58 27 -56 -7 -56 1008 0 795 2 937 15 967 26 62 39 64 445 64 l360 0 0 100 0 100 -387 -1 c-214 0 -402 -5 -419 -10z' />
      </g>
    </svg>
  );
};

export default ShareIcon;
