import React from 'react';

const IconReserva = ({ size = 24, color = '#000', strokeWidth = 0.2 }) => {
  return (
    <svg
      id="b"
      data-name="Capa_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.9 24.11"
      width={size}
      height={size}
      fill={color}
      stroke={color} 
      strokeWidth={strokeWidth} 
    >
      <g id="c" data-name="Capa_1">
        <g>
          <path d="M24.29,1.45c-.99-.92-2.33-1.45-3.66-1.45H5.03c-1.33,0-2.6.52-3.55,1.47C.57,2.37.05,3.57,0,4.84c-.04,1.2.38,2.37,1.19,3.32,1.01,1.19,2.54,1.89,4.09,1.89h4.1v5.23l-.52-.52c-.82-.82-2.16-.82-2.98,0-.82.82-.82,2.16,0,2.98l3.17,3.17.09,2.59c.01.34.29.61.64.61h9.58c.35,0,.64-.28.64-.64v-7.17c0-1.61-.39-2.69-1.16-3.24-.56-.4-1.15-.38-1.49-.33-.34-.13-.99-.3-1.84-.13-.6-.43-1.19-.6-1.92-.54v-2.03h7.3c1.34,0,2.6-.52,3.56-1.48.89-.91,1.41-2.1,1.46-3.36.05-1.39-.53-2.76-1.61-3.76ZM18.72,22.84h-8.33l-.05-1.57h8.38v1.57ZM14.95,13.79c.16.14.38.18.58.12.86-.25,1.42.04,1.44.05.15.09.35.1.52.05.01,0,.35-.11.63.1.23.17.61.67.61,2.19v3.69h-8.78l-3.16-3.16c-.32-.32-.32-.85,0-1.18.32-.33.85-.33,1.18,0l1.61,1.61c.06.06.13.11.21.14.16.06.33.06.49,0,.16-.06.28-.19.34-.34.03-.08.05-.16.05-.24h0V6.71c0-.46.37-.83.83-.83s.83.37.83.83v6.12c0,.2.09.38.24.5.15.12.36.16.54.12.95-.24,1.32-.09,1.84.34ZM23.54,7.67c-.71.71-1.65,1.1-2.65,1.1h-7.3v-2.06c0-1.16-.94-2.11-2.1-2.11s-2.11.94-2.11,2.11v2.06h-4.1c-1.18,0-2.35-.54-3.13-1.45-.6-.7-.91-1.57-.88-2.45.03-.95.42-1.84,1.1-2.51.72-.71,1.66-1.1,2.66-1.1h15.6c1.02,0,2.04.4,2.8,1.11.81.76,1.24,1.74,1.2,2.78-.04.94-.42,1.84-1.09,2.51Z" />
          <path d="M20.2,2.96l-2.64,2.64-1.04-1.04c-.25-.25-.65-.25-.9,0s-.25.65,0,.9l1.49,1.49c.12.12.29.19.45.19s.33-.06.45-.19l3.09-3.09c.25-.25.25-.65,0-.9s-.65-.25-.9,0Z" />
        </g>
      </g>
    </svg>
  );
};

export default IconReserva;
