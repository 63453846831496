const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('testKey', 'testValue');
    const value = localStorage.getItem('testKey');
    localStorage.removeItem('testKey');
    return value === 'testValue';
  } catch (e) {
    return false;
  }
};

const setLocalStorage = (key, value) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, value);
  } else {
    console.warn('LocalStorage is not available.');
  }
};

const getLocalStorage = (key) => {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(key);
  } else {
    console.warn('LocalStorage is not available.');
    return null;
  }
};

const deleteLocalStorage = (key) => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  } else {
    console.warn('LocalStorage is not available.');
  }
};

export default isLocalStorageAvailable;
export { setLocalStorage, getLocalStorage, deleteLocalStorage };
