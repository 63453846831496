import React from 'react';

const SuccessCheck = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='112'
      height='112'
      viewBox='0 0 112 112'
      fill='none'
    >
      <path
        fill-rule='evenodd'
        clipRule='evenodd'
        d='M61.8775 2.35881C58.4388 -0.78627 53.168 -0.78627 49.7293 2.35881L40.4896 10.8096C38.9325 12.2337 36.9245 13.0654 34.8165 13.1594L22.3074 13.7173C17.652 13.925 13.925 17.652 13.7173 22.3074L13.1594 34.8165C13.0654 36.9245 12.2337 38.9325 10.8096 40.4896L2.35881 49.7293C-0.78627 53.168 -0.78627 58.4388 2.35881 61.8775L10.8096 71.1172C12.2337 72.6743 13.0654 74.6823 13.1594 76.7903L13.7173 89.2994C13.925 93.9548 17.652 97.6818 22.3074 97.8894L34.8165 98.4473C36.9245 98.5414 38.9325 99.3731 40.4896 100.797L49.7293 109.248C53.168 112.393 58.4388 112.393 61.8775 109.248L71.1172 100.797C72.6743 99.3731 74.6823 98.5414 76.7903 98.4473L89.2994 97.8894C93.9548 97.6818 97.6818 93.9548 97.8894 89.2994L98.4473 76.7903C98.5414 74.6823 99.3731 72.6743 100.797 71.1172L109.248 61.8775C112.393 58.4388 112.393 53.168 109.248 49.7293L100.797 40.4896C99.3731 38.9325 98.5414 36.9245 98.4473 34.8165L97.8894 22.3074C97.6818 17.652 93.9548 13.925 89.2994 13.7173L76.7903 13.1594C74.6823 13.0654 72.6743 12.2337 71.1172 10.8096L61.8775 2.35881ZM78.6321 38.9763C80.1942 40.5384 80.1942 43.071 78.6321 44.6331L52.1321 71.1331C50.57 72.6952 48.0374 72.6952 46.4753 71.1331L32.9753 57.6331C31.4132 56.071 31.4132 53.5384 32.9753 51.9763C34.5374 50.4142 37.07 50.4142 38.6321 51.9763L49.3037 62.6478L72.9753 38.9763C74.5374 37.4142 77.07 37.4142 78.6321 38.9763Z'
        fill='#1D9BF0'
      />
    </svg>
  );
};

export default SuccessCheck;
