import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WelletContext } from '../../context/WelletContext';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setCanRefresh } from '../../actions/appActions';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import useForm from '../../helpers/useForm';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import { Spinner } from '../../components/Common/Spinner/Spinner';
import InputFormBorder from '../../components/Common/InputFormBorder/InputFormBorder';
import Select, { components } from 'react-select';
import countries from '../../../src/data/countrys.json';
import SolidChevron from '../../components/icons/SolidChevron';
import CalendarReservationIcon from '../../components/icons/CalendarReservationIcon';
import Modal from '../../components/Common/Modal/Modal';
import { getLocale } from '../../helpers/dates';
import { differenceInYears, format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import styles from './SignUp.module.css';

const SignUpPersonalInformation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const welletContext = useContext(WelletContext);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const [verifyingUser, setVerifyingUser] = useState(false);
  const [errorUser, setErrorUser] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState({ name: t('profile.nationality') });
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOver18, setIsOver18] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Estado para manejar si el formulario fue enviado
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    dispatch(setCanRefresh(false));
  }, []);

  function validate(values) {
    let errors = {};
    if (!values.firstName) {
      errors.firstName = t('emailError.EMPTY_EMAIL');
    }

    if (!values.lastName) {
      errors.lastName = t('nameError.EMPTY_NAME');
    }

    if (!values.nationality || values.nationality === '') {
      errors.nationality = t('selectCountry');
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = t('over18');
    }
    return errors;
  }

  const sendForm = async () => {
    setVerifyingUser(true);
    try {
      const result = await welletContext.apis.tickets.put(`users/updateUser`, {
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: values.dateOfBirth,
        countryId: values.nationality,
      });

      if (result.status === 200) {
        history.push({
          pathname: '/kyc-validate-finish',
          state: { response: { data: { success: true } } },
        });
      }

      setVerifyingUser(false);
    } catch (error) {
      console.error(error);
      setVerifyingUser(false);
      setErrorMessage(t('callApiError'));
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(() => {
    setIsSubmitted(true);
    sendForm();
  }, validate);

  const onChange = (e) => {
    setErrorUser('');
    handleChange(e);
  };

  const handleChangeDate = async (day) => {
    if (day !== undefined) {
      setSelectedDate(day);
      handleChange({
        target: { name: 'dateOfBirth', value: format(day, 'yyyy-MM-dd') },
        persist: () => {},
      });

      const isAdult = validateAge(day);
      setIsOver18(isAdult);
      setCalendarVisible(false);
    }
  };

  const handleChangeCountry = (selectedOption) => {
    handleChange({ target: { name: 'nationality', value: selectedOption.id }, persist: () => {} });
    setSelectedCountry(selectedOption);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='mr-3 p-0'>
        <SolidChevron size={18} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };

  const selectorStylesRp = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '.4rem',
      boxShadow: 'none',
      backgroundColor: '#000',
      border:
        !selectedCountry.id && isSubmitted
          ? '1px solid #f80005 !important'
          : '1px solid var(--color-primary) !important',
      borderRadius: '10px',
      height: '61.38px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: 'bold',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    menu: (provided, state) => ({
      ...provided,
      top: 'auto',
      bottom: 65,
      color: '#000',
    }),
  };

  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  const validateAge = (selectedDate) => {
    const currentDate = new Date();
    const age = differenceInYears(currentDate, selectedDate);
    return age >= 18;
  };

  return (
    <>
      <Header />
      <ContentApp background={true}>
        <div className=''>
          <div className='font-medium bold text-white' style={{ lineHeight: '1.6rem' }}>
            {t('signUpConcierge.personalInformation')}
          </div>
          <div className='mt-2 text-secondary subtitle' style={{ lineHeight: '1.3rem' }}>
            {t('signUpConcierge.completeInformation')}
          </div>
          <form onSubmit={handleSubmit}>
            <div className='mt-5'>
              <InputFormBorder
                name='firstName'
                type='text'
                value={values.firstName}
                error={errors.firstName}
                placeholder={t('profile.firstName')}
                label={t('profile.firstName')}
                onChange={onChange}
                customClass='mt-2'
              />
            </div>
            <div className='mt-4'>
              <InputFormBorder
                name='lastName'
                type='text'
                customClass='mt-2'
                value={values.lastName}
                error={errors.lastName}
                placeholder={t('profile.lastName')}
                label={t('profile.lastName')}
                onChange={onChange}
              />
            </div>
            <div className='mt-4 fade-up position-relative'>
              <Select
                className={`w-100 mt-2 hide-indicator-separator font-normal`}
                components={{ DropdownIndicator }}
                isSearchable={false}
                onTouchStart={handleTouchStart}
                styles={selectorStylesRp}
                options={countries}
                onChange={handleChangeCountry}
                placeholder={selectedCountry.name}
                label={t('profile.nationality')}
                value={values.nationality}
                formatOptionLabel={(o) => (
                  <div className='option-inner d-flex py-3'>
                    <span>{o.name}</span>
                  </div>
                )}
              />
              {errors.nationality && isSubmitted && (
                <div className='errorLabel mt-4' style={{ bottom: '-1.6rem' }}>
                  {errors.nationality}
                </div>
              )}
            </div>
            <div className={`${errors.nationality ? 'mt-5' : 'mt-3'} position-relative`}>
              <div className='field-border-container'>
                <div
                  className='field-border mt-2'
                  style={{ border: isOver18 || !isSubmitted ? '' : '1px solid red' }}
                >
                  <div
                    className='font-medium sentences-capitalize'
                    onClick={() => setCalendarVisible(true)}
                    style={{ padding: '0.61rem 0.7rem' }}
                  >
                    {format(selectedDate, 'PPP', { locale })}
                    <CalendarReservationIcon
                      size={'1.5rem'}
                      color={'var(--color-primary)'}
                      styles={{ marginLeft: '0.5rem', marginTop: '-2px' }}
                    />
                  </div>
                  <span className='floatingPlaceholder '>{t('profile.dateOfBirth')}</span>
                </div>
              </div>
              {!isOver18 && isSubmitted && (
                <div className='errorLabel mt-4' style={{ bottom: '-1.6rem' }}>
                  <span></span> {t('over18')}
                </div>
              )}
            </div>
            <div className='mt-2'>{errorMessage && <ErrorBox title={errorMessage} />}</div>
            <Button
              className='btn-primary mt-5'
              size='large'
              fontSize={'18px'}
              text={
                verifyingUser ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner size={25} color='#000' />
                  </div>
                ) : (
                  t('continue')
                )
              }
              icon={verifyingUser ? false : true}
              typeIcon='arrow'
              iconColor='#000'
              onClick={() => setIsSubmitted(true)}
            />

            <Modal
              open={calendarVisible}
              animationClass='fade-up-horizontal'
              showCloseButton={true}
              onClose={() => setCalendarVisible(false)}
            >
              <div className='bg-white'>
                <DayPicker
                  locale={locale}
                  captionLayout='dropdown'
                  className='day-picker-buytickets'
                  mode='single'
                  selected={selectedDate}
                  onSelect={(day) => handleChangeDate(day)}
                  defaultMonth={new Date()}
                  fromDate={new Date('1924-01-01')}
                  toDate={new Date()}
                />
              </div>
            </Modal>
          </form>
        </div>
      </ContentApp>
    </>
  );
};

export default SignUpPersonalInformation;
