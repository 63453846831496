import axios from 'axios';
import { saveSettings } from '../actions/orderActions';
import { resetCalendarState } from './calendarActions';
import { resetOrderState } from './orderActions';
import { resetProductsState } from './productsActions';

export const init = () => async (dispatch) => {
  const settings = {
    language: 'es', //todo: this should come from user settings
  };

  dispatch(saveSettings(settings));
  dispatch(appReady());
};

export const appReady = (language) => {
  return {
    type: 'APP_READY',
    payload: language,
  };
};

export const fetchShow = async (
  lang,
  cityId,
  page,
  pageSize,
  welletContext,
  searchQuery,
  categoryId,
  subcategoryId,
  organizationId,
) => {
  const response = await welletContext.apis.tickets.post('/shows/getAll', {
    lang: lang,
    cityId: cityId,
    page: page,
    pageSize: pageSize,
    nameFilter: searchQuery,
    categoryId: categoryId,
    subcategoryId: subcategoryId,
    organizationId,
  });

  return response.data;
};

export const setTenantId = (tenantId) => ({
  type: 'SET_TENANT_ID',
  payload: tenantId,
});

const setAppData = (data) => ({
  type: 'SET_APP_DATA',
  payload: data,
});

export const fetchAppData = (tenantId) => async (dispatch) => {
  if (tenantId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SALES_API_URL}/organization/entity/${tenantId}`,
      );
      dispatch(setAppData(response.data));

      // set title and favicon based on app data
      document.title = response.data.name;
      const favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.href = response.data.favIconUrl;
      document.head.appendChild(favicon);
    } catch (error) {
      console.error(error);
    }
  }
};

export const setScopeOrganization = (affiliation, user) => {
  affiliation.userId = user.sid;
  return {
    type: 'SET_SCOPE_ORGANIZATION',
    payload: affiliation,
  };
};

export const resetAll = () => (dispatch) => {
  dispatch(resetCalendarState());
  dispatch(resetOrderState());
  dispatch(resetProductsState());
};

export const setCanRefresh = (value) => ({
  type: 'SET_CAN_REFRESH',
  payload: value,
});

export const setLanguage = (language) => {
  return {
    type: 'SET_LANGUAGE',
    payload: language,
  };
};
