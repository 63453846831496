import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { WelletContext } from '../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import useForm from '../../helpers/useForm';
import SolidChevron from '../../components/icons/SolidChevron';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import styles from './SignUp.module.css';
import { Spinner } from '../../components/Common/Spinner/Spinner';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';

export default function SignUpScreen() {
  const { t } = useTranslation();
  const history = useHistory();
  const [phoneError, setPhoneError] = useState('');
  const [sendingCode, setSendingCode] = useState(false);
  const welletContext = useContext(WelletContext);
  const tenantId = useSelector((state) => state.app.tenantId);
  const countryCode = useSelector((state) => state.app.countryCode);
  const language = useSelector((state) => state.app.language);
  function validate(values) {
    let errors = {};
    if (!values.phone) {
      errors.phone = t('phoneError.EMPTY_PHONE_NUMBER');
      setPhoneError(errors.phone);
    } else {
      setPhoneError('');
    }

    if (values.phone && !isValidPhoneNumber(values.phone)) {
      errors.phone = t('phoneError.INVALID_PHONE_NUMBER');
      setPhoneError(errors.phone);
    }

    return errors;
  }

  const requestCodeVerification = async () => {
    setSendingCode(true);
    try {
      await welletContext.apis.tickets.post(`users/whatsapp/code`, {
        Phone: values.phone,
        tenantId: tenantId,
        countryCode: countryCode,
        language: language,
      });
      history.push({
        pathname: '/sign-up-phone-validation',
        search: `?phone=${values.phone}&countryCode=${countryCode}`,
      });
    } catch (error) {
      let errorMessage;
      switch (error.response.data.errorCode) {
        case 'PHONE_NOT_AVAILABLE':
          errorMessage = t('phoneError.PHONE_NUMBER_ALREADY_IN_USE');
          break;
        case 'MAX_ATTEMPTS':
          errorMessage = t('phoneError.MAXIMUM_ATTEMPTS');
          break;
        case 'SEND_FAILURE':
          errorMessage = t('phoneError.SEND_FAILURE');
          break;
        default:
          errorMessage = t('callApiError');
      }
      setPhoneError(errorMessage);
    }
    setSendingCode(false);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(requestCodeVerification, validate);

  const change = (e) => {
    handleChange({ target: { name: 'phone', value: e }, persist: () => {} });
    setPhoneError('');
  };

  return (
    <>
      <Header pathName='/login' hasBackground={true} />
      <ContentApp background={true}>
        <div className='font-medium bold' style={{ color: '#fff' }}>
          {t('signUp.title1')}
        </div>
        <div className='mt-1 text-secondary subtitle'>{t('signUp.subtitle1')}</div>
        <form onSubmit={handleSubmit}>
          <div className={`mt-5 ${styles.signUp}`}>
            <div className='text-secondary bold font-small mt-1'>{t('signUp.whatsappNumber')}</div>
            <div className={` mt-1 phone w-100 focus reservation-phone `}>
              <div className={`field-border-container mt-4 ${phoneError ? 'error' : ''}`}>
                <div className={`field-border`}>
                  <div className='field-border-name'>{t('customerWhatsApp')}</div>
                  <div className={`phone w-100 focus`}></div>
                  <div className={styles.phoneInputContainer}>
                    <PhoneInputWithCountrySelect
                      className={`${phoneError ? 'phone-error phone-check' : ''}`}
                      name='phone'
                      type='phone'
                      inputMode='numeric'
                      international={true}

                      value={values.phone || ''}
                      defaultCountry={countryCode}
                      onChange={change}
                    />
                    <span className={styles.chevronIcon}>
                      <SolidChevron size={15} color='var(--color-primary)' />
                    </span>
                  </div>
                </div>
              </div>

              {phoneError && (
                <div className='mt-1'>
                  <ErrorBox title={t('phoneError.DEFAULT')} description={phoneError}>
                    {' '}
                  </ErrorBox>
                </div>
              )}
            </div>
          </div>
          <div className='mt-4'>
            <Button
              fontSize={'18px'}
              size='large'
              hasBorder={true}
              icon={sendingCode ? false : true}
              typeIcon='arrow'
              iconColor='#000'
              className='btn-primary'
              text={
                sendingCode ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner size={25} color='#000' />
                  </div>
                ) : (
                  t('continue')
                )
              }
            />
          </div>
        </form>
      </ContentApp>
    </>
  );
}
