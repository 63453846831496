import React from 'react';

const StripeIcon = ({ size, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 103 102" fill="none">
    <g clip-path="url(#clip0_2379_4126)">
    <path d="M51.1863 69.8626C49.6506 69.7157 48.5755 68.5339 48.3953 66.7778C48.2083 64.9549 46.933 63.7597 45.2437 63.8265C43.6011 63.8933 42.3524 65.2821 42.3458 67.0449C42.3324 70.1898 44.3155 73.4149 47.2935 74.8772C48.1282 75.2912 48.5021 75.7118 48.4153 76.6666C48.3018 77.8952 48.856 78.8835 49.9243 79.5245C51.9475 80.7464 54.3646 79.2908 54.4715 76.8135C54.4982 76.1458 54.2778 75.5048 55.2126 75.0508C59.2723 73.0877 61.1753 69.1282 60.2672 64.808C59.4326 60.8619 55.9471 58.0241 51.607 57.7703C49.7107 57.6568 48.3886 56.4015 48.3953 54.7189C48.4086 53.1965 49.7173 51.8143 51.2664 51.7075C52.9023 51.594 54.2711 52.9094 54.4648 54.7857C54.6584 56.682 55.9938 57.9039 57.7165 57.757C59.4192 57.6034 60.6478 56.1545 60.5009 54.3116C60.2205 50.8595 58.5913 48.2354 55.473 46.6662C54.7252 46.2923 54.4314 45.9117 54.4447 45.0571C54.4848 43.0139 53.1494 41.5916 51.3733 41.6317C49.6439 41.6717 48.3819 43.0606 48.4153 45.037C48.4286 45.8783 48.1682 46.279 47.4004 46.6529C43.6612 48.4824 41.6981 52.5488 42.5394 56.5484C43.4008 60.6749 46.8929 63.6262 51.1396 63.8131C53.4298 63.9133 54.9322 65.7095 54.3312 67.6258C53.8839 69.048 52.5618 69.9895 51.1796 69.8626H51.1863Z" fill="#1D9BF0"/>
    <path d="M24.879 37.7793H9.85547V68.9081H24.879V37.7793Z" fill="#1D9BF0"/>
    <path d="M5.64844 78.0429V84.0523H29.0852V78.0429C29.0852 76.3202 27.683 74.918 25.9603 74.918H8.77334C7.05064 74.918 5.64844 76.3202 5.64844 78.0429Z" fill="#1D9BF0"/>
    <path d="M100.811 20.4451L52.8963 0.29351C51.9615 -0.100441 50.9065 -0.100441 49.9717 0.29351L2.05656 20.4451C0.814611 20.9726 0 22.1945 0 23.5433V28.845C0 30.5276 1.36214 31.8897 3.04478 31.8897H99.8232C101.506 31.8897 102.868 30.5276 102.868 28.845V23.5433C102.868 22.1945 102.053 20.9726 100.811 20.4451ZM71.4855 22.2146C70.9112 22.7955 70.11 23.156 69.2219 23.156H33.6461C31.8767 23.156 30.4411 21.7205 30.4411 19.951C30.4411 19.0696 30.8016 18.2684 31.3826 17.6875C31.9568 17.1066 32.7581 16.746 33.6461 16.746H69.2219C70.9914 16.746 72.4269 18.1816 72.4269 19.951C72.4269 20.8324 72.0664 21.6337 71.4855 22.2146Z" fill="#1D9BF0"/>
    <path d="M93.0118 37.7793H77.9883V68.9081H93.0118V37.7793Z" fill="#1D9BF0"/>
    <path d="M100.705 89.3398H2.16339C0.968185 89.3398 0 90.308 0 91.5032V99.7629C0 100.998 1.00157 102 2.23684 102H100.631C101.866 102 102.868 100.998 102.868 99.7629V91.5032C102.868 90.308 101.9 89.3398 100.705 89.3398Z" fill="#1D9BF0"/>
    <path d="M73.7832 78.0429V84.0523H97.22V78.0429C97.22 76.3202 95.8178 74.918 94.0951 74.918H76.9081C75.1854 74.918 73.7832 76.3202 73.7832 78.0429Z" fill="#1D9BF0"/>
    </g>
    <defs>
    <clipPath id="clip0_2379_4126">
    <rect width="102.868" height="102" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

export default StripeIcon;
