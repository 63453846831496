import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Common/Modal/Modal';
import { WelletContext } from '../../context/WelletContext';
import Button from '../Common/Button/Button';
import InputFormBorder from '../Common/InputFormBorder/InputFormBorder';
import ErrorBox from '../Common/ErrorBox/ErrorBox';

const NicknameModal = ({ isOpen, onClose, onNicknameSet }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [nickNameValue, setNickNameValue] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const validChars = /^[a-zA-Z0-9_]+$/;

  const handleNicknameValueChange = (event) => {
    const value = event.target.value;
    setNickNameValue(value);
    setErrorMessage('');

    if (!validChars.test(value)) {
      setErrorMessage(t('nickName.invalidnickName'));
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(value === '');
    }
  };

  const handleModalSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await welletContext.apis.tickets.post(
        `/users/setNickName?nickName=${nickNameValue}`,
      );
      onNicknameSet();
      onClose();
    } catch (error) {
        if (error.response) {
          switch (error.response.data) {
            case 'NICKNAME_ALREADY_EXISTS':
              setErrorMessage(t('nickName.nicknameAlreadyExists'));
              break;
            case 'NICKNAME_HAS_INVALID_CHARACTERS':
              setErrorMessage(t('nickName.nicknameHasInvalidCharacters'));
              break;
            default:
              console.error('Error no controlado', error);
          }
        } else {
          console.error('Error inesperado:', error);
        }
      }
  };
  return (
    <Modal hasBackground={true} animationClass='fade' onClose={onClose} open={isOpen}>
      <form onSubmit={handleModalSubmit}>
        <div className='title'>{t('nickName.titleNickName')}</div>
        <h5 className='text-center fs-5 mb-4 text-description'>
          {t('nickName.explanationNickname')}
        </h5>
        <InputFormBorder
          type='text'
          value={nickNameValue}
          onChange={handleNicknameValueChange}
          placeholder={t('nickName.enterYourNickName')}
        />
        {errorMessage && (
          <div>
            <ErrorBox title={errorMessage} />
          </div>
        )}
        <Button
          size='small'
          className={`col mr-2 btn-primary mt-3 btn-account ${isButtonDisabled ? 'disabled' : ''}`}
          text={t('continue')}
          disabled={isButtonDisabled}
        />
      </form>
    </Modal>
  );
};

export default NicknameModal;
