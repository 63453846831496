import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getDateFromOffset, getLocale } from '../../helpers/dates';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Calendar, { generateDisabledDates } from '../Common/Calendar/Calendar';
import DataManager from '../../context/DataManager';
import SummarySelector from './SummarySelector/SummarySelector';
import BalanceAmount from './BalanceAmount/BalanceAmount';
import Button from '../Common/Button/Button';
import Money from '../Common/Money/Money';
import ActivityBalanceItem from '../Activities/ActivityBalanceItem/ActivityBalanceItems';
import { EyeIcon } from '../icons/EyeIcon';
import { EyeHideIcon } from '../icons/EyeHideIcon';
import ChevronIcon from '../icons/chevronIcon';

const Balance = ({
  title,
  todayBalance,
  last7DaysBalance,
  showAllBalances = false,
  loading,
  loadingSummary,
  mpVerified,
  history,
  summary,
  selectedDate,
  handleChangeDate,
  setCalendarVisible,
  calendarVisible,
  showArrow = false,
}) => {
  const { t } = useTranslation();
  const { showAmount, setShowAmount } = useContext(DataManager);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const user = useSelector((state) => state.associates.user);
  const today = new Date(getDateFromOffset(user.companyUtcMinutesOffset));
  const [selectDisabled, setSelectDisabled] = useState(false);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const isToday = selectedDate === format(today, 'yyyy-MM-dd');
  const disabledDates = generateDisabledDates(new Date(today), lastDayOfMonth, locale);
  const calendarRef = useRef(null);
  const minDate = format(
    new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
    'yyyy-MM-dd',
    { locale },
  );
  const maxDate = format(today, 'yyyy-MM-dd', { locale });
  const kycStatus = useSelector((state) => state.associates.user.kycStatus);
  const isCashier = user?.roles?.includes('cashier');
  const wireMethod =
    user.withdrawalMethod === 'WIRE_INTERNATIONAL' || user.withdrawalMethod === 'WIRE_MEXICO';
  const cashOrCertificate =
    user.withdrawalMethod === 'CASH' || user.withdrawalMethod === 'CERTIFICATE';

  const toggleCalendarVisibility = () => {
    setCalendarVisible(!calendarVisible);
    setSelectDisabled(true);
  };

  const enableSelect = () => {
    setSelectDisabled(false);
  };

  const toggleAmountVisibility = () => {
    setShowAmount(!showAmount);
  };

  useEffect(() => {
    if (calendarVisible) {
      const handleClickOutsideCalendar = (event) => {
        if (calendarVisible && calendarRef.current) {
          const calendarElement = calendarRef.current;
          const selectElement = document.querySelector('.css-c4kpnr-placeholder');
          if (!calendarElement.contains(event.target) && !selectElement.contains(event.target)) {
            setCalendarVisible(false);
            enableSelect();
          }
        }
      };

      document.addEventListener('click', handleClickOutsideCalendar);

      return () => {
        document.removeEventListener('click', handleClickOutsideCalendar);
      };
    }
  }, [calendarVisible]);

  const eyeIcon = (
    <span onClick={toggleAmountVisibility} className='ml-2 mb-1'>
      <EyeIcon color='#fff' size='17px' />
    </span>
  );

  const eyeHideIcon = (
    <span onClick={toggleAmountVisibility} className='ml-2 mb-1'>
      <EyeHideIcon color='#fff' size='19px' />
    </span>
  );

  const renderLinkPaymentMethod = () => {
    if (!mpVerified && !loading && !isCashier && !cashOrCertificate) {
      let description = '';
      let buttonText = '';
      let nextUrl = '';

      if (user.withdrawalMethod === 'STRIPE') {
        description = t('stripe.linkingStripeAccountDescription');
        buttonText = t('stripe.linkingStripeAccountButton');
        nextUrl = '/stripeaccount';
      } else if (user.withdrawalMethod === 'MERCADOPAGO') {
        description = t('mercadoPago.linkingMpAccountDescription');
        buttonText = t('mercadoPago.linkingMpAccountButton');
        nextUrl = '/mercadopagoaccount';
      } else if (wireMethod) {
        description = t('WIRE.linkingBankAccountDescription');
        buttonText = t('WIRE.linkingBankAccountButton');
        if (user.withdrawalMethod === 'WIRE_INTERNATIONAL') {
          nextUrl = '/international-bank';
        } else if (user.withdrawalMethod === 'WIRE_MEXICO') {
          nextUrl = '/national-bank';
        }
      }

      return (
        <>
          <div className='text-secondary my-2'>{description}</div>
          <Button
            text={buttonText}
            size='large'
            icon
            typeIcon='arrow'
            color='#000'
            className='btn-primary'
            iconColor='#000'
            onClick={() => history.push(nextUrl)}
            disabled={kycStatus !== 'APPROVED'}
          />
        </>
      );
    }
  };

  const renderActivitiesItems = () => {
    if (summary === null || !summary?.details || summary?.details.length === 0 || loadingSummary)
      return null;

    return (
      <div className='fade-up'>
        {!loadingSummary
          ? summary?.details.map((d, i) => (
              <div key={i}>
                <ActivityBalanceItem
                  activity={d}
                  key={i}
                  date={d.date}
                  rightText={d.amount}
                  className={'px-0 py-2'}
                />

                <div
                  className={`${
                    i < summary?.details?.length - 1 ? 'activity-item-border my-2' : 'display-none'
                  }`}
                ></div>
              </div>
            ))
          : null}
      </div>
    );
  };
  return (
    <div className='card associate-balance position-relative px-3 py-2'>
      <div className='d-flex'>
        <div className='text-lighter-gray font-1rem fw-extrabold'>{title}</div>
        {/* {showAmount ? eyeIcon : eyeHideIcon} */}
      </div>
      {!showAllBalances && (
        <SummarySelector
          toggleCalendarVisibility={toggleCalendarVisibility}
          enableSelect={enableSelect}
          selectDisabled={selectDisabled}
          isToday={isToday}
          selectedDate={selectedDate}
          locale={locale}
          calendarVisible={calendarVisible}
          handleChangeDate={handleChangeDate}
        />
      )}
      <BalanceAmount
        loadingSummary={loadingSummary}
        showAllBalances={showAllBalances}
        showAmount={true}
        todayBalance={todayBalance}
        last7DaysBalance={last7DaysBalance}
        loading={loading}
        isToday={isToday}
        selectedDate={selectedDate}
        locale={locale}
        today={today}
      />
      {calendarVisible && (
        <div ref={calendarRef}>
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            onSelectDate={handleChangeDate}
            selectedDate={selectedDate}
            disabledDates={disabledDates}
            className='calendar-balance'
          />
        </div>
      )}
      <div
        className={`${
          summary?.details?.length > 0 ? 'activity-item-border mt-3 w-100 p-0' : 'display-none'
        }`}
      ></div>
      {renderLinkPaymentMethod()}
      {renderActivitiesItems()}
      {showArrow && !loading && (
        <div className='fade-up' style={{ position: 'absolute', right: '0px', bottom: '.6rem' }}>
          <ChevronIcon size='1.063rem' fillColor='#fff' />
        </div>
      )}
      {todayBalance?.total > 0 ? (
        <div className='py-3 mt-2' style={{ borderTop: '1px solid #212121' }}>
          {todayBalance?.restaurant.map((p) => (
            <div className='row py-2 ' key={p.id}>
              <div className='col'>{p.name}</div>
              <div className='col-auto'>
                <Money value={p.total} lowDecimals decimal={false} />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Balance;
