import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getQueryString } from '../../helpers/url';
import { WelletContext } from '../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import { InputSingleCharacter } from '../../components/Common/InputSingleCharacter/InputSingleCharacter';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import { Spinner } from '../../components/Common/Spinner/Spinner';

const SignUpPhoneValidationScreen = () => {
  const { t } = useTranslation();
  const tenant = useSelector((state) => state.app.tenantId);
  const history = useHistory();
  const phone = getQueryString('phone');
  const welletContext = useContext(WelletContext);
  const countryCode = getQueryString('countryCode');
  const codeLength = 4;
  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [btnVerifyDisable, setBtnVerifyDisable] = useState(true);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  const onVerifyHandle = (token) => {
    history.push({
      pathname: '/sign-up-email-validation',
      search: '?phone=' + phone + '&countryCode=' + countryCode + '&token=' + token,
    });
  };

  const handleChangeEmail = () => {
    history.push({
      pathname: '/sign-up',
    });
  };

  const requestCodeVerification = async () => {
    if (secondsRemaining > 0) {
      return;
    }
    setSendingCode(true);
    try {
      await welletContext.apis.tickets.post(`users/whatsapp/code`, {
        Phone: phone,
        tenantId: tenant,
        CountryCode: countryCode,
        language: 'es',
      });
      startTimer();
    } catch (error) {
      console.error(error);
    }
    setSendingCode(false);
  };

  const sendCodeVerification = async (code) => {
    setVerifyingCode(true);
    try {
      const result = await welletContext.apis.tickets.post(`users/whatsapp/validation/code`, {
        phone: phone,
        tenantId: tenant,
        code: code,
      });

      if (result.status === 200) {
        onVerifyHandle(result.data.token);
      }
    } catch (error) {
      console.error(error);
      setErrorCode(t('signUp.codeError'));
    }
    setVerifyingCode(false);
  };

  const onCodeChange = (value) => {
    setCode(value);
    setErrorCode('');
  };

  const verifyCode = () => {
    sendCodeVerification(code);
  };

  const startTimer = () => {
    setSecondsRemaining(120);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [secondsRemaining]);

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (code.length === codeLength) setBtnVerifyDisable(false);
    else setBtnVerifyDisable(true);
  }, [code]);

  return (
    <>
      <Header title={''} pathName={'/sign-up'} hasBackground={true} />
      <ContentApp background={true}>
        <div className=''>
          <div className='font-medium bold text-white' style={{ lineHeight: '1.6rem' }}>
            {t('signUp.title2')}
          </div>
          <div className='mt-2 text-secondary subtitle' style={{ lineHeight: '1.3rem' }}>
            {t('signUp.subtitle2')}: {phone}.
          </div>
          <div className='mt-4 input-character'>
            <InputSingleCharacter
              length={codeLength}
              onValueChange={(result) => onCodeChange(result)}
            />
          </div>

          <div className='mt-3'>
          {errorCode && <ErrorBox title={errorCode} />}
            </div>
       

          <div className='mt-5'>
            <Button
              className={`${verifyingCode ? 'loading' : ''} btn-primary`}
              onClick={verifyCode}
              size={'large'}
              fontSize={'18px'}
              disabled={btnVerifyDisable}
              text={
                verifyingCode ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner size={25} color='#000' />
                  </div>
                ) : (
                  t('continue')
                )
              }
              icon={verifyingCode ? false : true}
              typeIcon='arrow'
              iconColor='#000'
            />
            <Button
              className={`mt-2 btn-primary`}
              size={'large'}
              fontSize={'18px'}
              onClick={requestCodeVerification}
              disabled={secondsRemaining > 0}
              text={
                sendingCode ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner size={25} color='#000' />
                  </div>
                ) : (
                  <>
                    {secondsRemaining > 0
                      ? `${t('signUp.resendCode')} ${Math.floor(secondsRemaining / 60)}:${(
                          secondsRemaining % 60
                        )
                          .toString()
                          .padStart(2, '0')}`
                      : 'Reenviar código'}
                  </>
                )
              }
              type='button'
            />
          </div>
          <div
            className='text-secondary text-center'
            style={{ position: 'fixed', bottom: 40, width: '100%', left: 0 }}
            onClick={() => handleChangeEmail()}
          >
            {t('signUp.otherPhoneNumber')}
          </div>
        </div>
      </ContentApp>
    </>
  );
};
export default SignUpPhoneValidationScreen;
