import { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { getQueryString } from '../../../helpers/url';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ReservationTypeCard from '../../../components/ReservationTypeCard/ReservationTypeCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetReservation } from '../../../actions/reservationActions';
import useGenericTranslation from '../../../helpers/translate';

const ReservationType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const showId = getQueryString('showId');
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(null);
  const app = useSelector((state) => state.app);
  const { getClientTranslation } = useGenericTranslation();

  const getShow = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`/shows/get/`, {
        params: {
          showId: showId,
          lang: language,
        },
      });

      setShow(result.data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    dispatch(resetReservation());
    getShow();
  }, []);

  const handleClick = (reservationType) => {
    history.push({
      pathname:
        reservationType === 'restaurant'
          ? '/reservation-restaurant'
          : '/reservation/' + reservationType,
      search: `?showId=${showId}`,
    });
  };

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <Header title={<div className='text-capitalize px-3'>{t('book') + ' ' + show.name}</div>} />
      <ContentApp>
        {show?.reservationTypes?.map((r) => {
          return (
            <div className='mt-3' key={r.type}>
              <ReservationTypeCard
                title={getClientTranslation(`reservation${r.type.toLowerCase()}`)}
                image={
                  app?.name?.toLowerCase().includes('coco')
                    ? `/images/cb-reservation-${r.type.toLowerCase()}.png`
                    : app?.name?.toLowerCase().includes('rosa')
                    ? `/images/rosa-reservation-${r.type.toLowerCase()}.png`
                    : app?.name?.toLowerCase().includes('sounds')
                    ? `/images/sounds-reservation-${r.type.toLowerCase()}.png`
                    : null
                }
                onHandleClick={() => handleClick(r.type.toLowerCase())}
              />
            </div>
          );
        })}
      </ContentApp>
    </>
  );
};

export default ReservationType;
