import React from 'react';
import styles from './button.module.css';

const ButtonReservation = ({
  leftContent,
  rightContent,
  size,
  type = 'submit',
  onClick,
  className,
  disabled = false,
  backgroundColor = 'var(--color-primary)',
  fixed = false,
}) => {
  const sizeConfig = {
    large: {
      widthConfig: '100%',
      height: '4rem',
    },
    normal: {
      widthConfig: '100%',
      height: '3.2rem',
      fontSizeConfig: '1rem',
    },
  };

  const { height, widthConfig, fontSizeConfig } = sizeConfig[size];

  const buttonStyles = {
    width: widthConfig,
    height,
    paddingLeft:'10px',
    paddingRight:'10px',
    fontSize: fontSizeConfig,
    backgroundColor: backgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const leftStyles = {
    flex: '0 0 70%',
    textAlign: 'left',
  };

  const rightStyles = {
    flex: '0 0 30%',
    textAlign: 'right',
  };

  return (
    <button
      style={buttonStyles}
      className={`${styles.buttonWellet} bold ${className ? className : null}
      ${disabled && styles.disabledOpacity}
      ${fixed && styles.buttonWelletFixed}
      `}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <div style={leftStyles}>{leftContent}</div>
      <div style={rightStyles}>{rightContent}</div>
    </button>
  );
};

export default ButtonReservation;
