import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { setCookie } from '../../helpers/cookie';
import { useTranslation } from 'react-i18next';
import styles from './CitySelector.module.css';
import ChevronIcon from '../icons/chevronIcon';
import ContentApp from '../ContentApp';
import SkeletonCities from '../Skeletons/SkeletonCities';
import DataManager from '../../context/DataManager';
import { WelletContext } from '../../context/WelletContext';
import Header from '../Common/Header/Header';

const CitySelector = () => {
  const { t } = useTranslation();
  const { setCitySelected } = useContext(DataManager);
  const [cities, setCities] = useState(null);
  const [loading, setLoading] = useState(true);
  const welletContext = useContext(WelletContext);
  const location = useLocation(); 
  const history = useHistory();
  const fromPath = location.state?.from || '/restaurant-list';

  const getCities = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get('/shows/cities');
      setCities(response.data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!cities) {
      getCities();
    }
  }, [cities]);

  const onCityClick = (citySelected) => {
    setCitySelected(citySelected.id);
    try {
      setCookie('selectedCity', JSON.stringify(citySelected.id), 1440);
    } catch (error) {
      console.error(error);
    }

  
    history.push(fromPath);
  };

  const cityLongName = (longName) => {
    const longNameSplit = longName.split(',');
    return longNameSplit[1] ? longNameSplit[1] + ',' + longNameSplit[2] : longName;
  };

  return (
    <>
      <Header title={t('chooseACity')} />
      <ContentApp>
        {!loading ? (
          <div className='mt-4'>
            {cities.map((c, index) => (
              <div
                key={index}
                className={`${styles.cityCard} mt-2 p-4 fade-up`}
                onClick={() => onCityClick(c)}
              >
                <div className='col p-0 ml-3'>
                  <div className='bold font-normal text-white'>{c.name}</div>
                  <div className='text-secondary font-small'>{cityLongName(c.nameLong)}</div>
                </div>
                <div className='col-2 pr-0'>
                  <ChevronIcon fillColor={'var(--color-primary)'} size={35} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <SkeletonCities />
        )}
      </ContentApp>
    </>
  );
};

export default CitySelector;
