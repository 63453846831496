import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import step1 from '../../../../images/Onboarding/FirstOnboarding.png';
import step2 from '../../../../images/Onboarding/SecondOnboarding.png';
import step3 from '../../../../images/Onboarding/ThirdOnboarding.png';
import Button from '../../../../components/Common/Button/Button';
import ContentApp from '../../../../components/ContentApp';
import Header from '../../../../components/Common/Header/Header';
import { WelletContext } from '../../../../context/WelletContext';
import imgPrincipal from '../../../../images/campaignsOnboarding.png';
import InputFormBorder from '../../../../components/Common/InputFormBorder/InputFormBorder';
import ErrorBox from '../../../../components/Common/ErrorBox/ErrorBox';
import Modal from '../../../../components/Common/Modal/Modal';
import { useDispatch } from 'react-redux';
import { nickNameSetup } from '../../../../actions/associatesActions';
import styles from './Onboarding.module.css';
const OnboardingCampaigns = () => {
  const welletContext = useContext(WelletContext);
  const [isCarouselVisible, setCarouselVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [nickNameValue, setNickNameValue] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const steps = [
    {
      title: t('onboardingCarousel.image1.title'),
      subtitle: t('onboardingCarousel.image1.subtitle'),
      image: step1,
    },
    {
      title: t('onboardingCarousel.image2.title'),
      subtitle: t('onboardingCarousel.image2.subtitle'),
      image: step2,
    },
    {
      title: t('onboardingCarousel.image3.title'),
      subtitle: t('onboardingCarousel.image3.subtitle'),
      image: step3,
    },
  ];

  const handleNickNameValueChange = (event) => {
    setNickNameValue(event.target.value);
    setErrorMessage('');
    setIsButtonDisabled(event.target.value === '');
  };

  const handleModalSubmit = async (event) => {
    event.preventDefault();
    try {
      await dispatch(nickNameSetup(welletContext, nickNameValue));
      setOpenModal(false);
      history.push('/campaigns-feed');
      firstOnboarding();
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data === 'NICKNAME_ALREADY_EXISTS'
      ) {
        setErrorMessage(t('nickName.nicknameAlreadyExists'));
      } else {
        console.error(error);
      }
    }
  };

  const firstOnboarding = async () => {
    try {
      await welletContext.apis.tickets.patch('/Campaign/onboarding');
    } catch (error) {
      console.error(error);
    }
  };

  const startCarousel = () => {
    setCarouselVisible(true);
  };

  const handleDotClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <>
      <Modal
        hasBackground={true}
        animationClass='fade'
        onClose={() => setOpenModal(false)}
        open={openModal}
      >
        <form onSubmit={handleModalSubmit}>
          <div className={styles.titleNickName}>{t('nickName.titleNickName')}</div>
          <h5 className={`${styles.textDescription} text-center fs-5 mb-4 text-description`}>
            {t('nickName.explanationNickname')}
          </h5>
          <InputFormBorder
            type='text'
            value={nickNameValue}
            onChange={handleNickNameValueChange}
            placeholder={t('nickName.enterYourNickName')}
          />
          {errorMessage && (
            <div>
              <ErrorBox title={errorMessage} />
            </div>
          )}
          <Button
            size='large'
            className={`${styles.fixedButton} col mr-2 btn-primary mt-3 btn-account ${
              isButtonDisabled ? 'disabled' : ''
            }`}
            text={t('continue')}
            disabled={isButtonDisabled}
          />
        </form>
      </Modal>
      {!isCarouselVisible ? (
        <>
          <Header
            rightText={t('onboarding.skip')}
            onClickRight={() => {
              setOpenModal(true);
            }}
          />
          <ContentApp>
            <div>
              <div className='position-relative'>
                <div className='text-center'>
                  <img src={imgPrincipal} alt='' className={styles.imgPrincipalOnboarding} />
                </div>
                <div className='mt-4 ml-3 mr-3'>
                  <article className={`${styles.title} title`}>
                    <span>
                      {t('onboardingCarousel.main.partOneTitle')}

                      <span style={{ fontStyle: 'italic' }}>
                        {t('onboardingCarousel.main.partTwoTitle')}
                      </span>
                    </span>{' '}
                    {t('onboardingCarousel.main.partThreeTitle')}
                  </article>
                  <article className={`${styles.textDescription} text-description`}>
                    {t('onboardingCarousel.main.partOneDescription')}
                  </article>
                </div>
              </div>

              <div
                className='position-fixed w-100'
                style={{
                  bottom: 0,
                  left: 0,
                  paddingBottom: 'env(safe-area-inset-bottom)',
                }}
              >
                <div className='container'>
                  <div className='row justify-content-center'>
                    <div className='col-12 col-md-7 col-lg-6'>
                      <Button
                        className='mb-3 '
                        text={t('Comenzar')}
                        size='large'
                        onClick={startCarousel}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ContentApp>
        </>
      ) : (
        <>
          <Header
            showBack={true}
            rightText={t('onboarding.skip')}
            onClickRight={() => {
              setOpenModal(true);
            }}
          />
          <ContentApp>
            <div className={styles.flexMainContainer}>
              <div className={styles.flexContent}>
                <Swiper
                  effect='slide'
                  ref={swiperRef}
                  modules={[Navigation, Pagination]}
                  spaceBetween={100}
                  slidesPerView={1}
                  onSlideChange={(swiper) => setCurrentStep(swiper.activeIndex)}
                  className={styles.swiperContainer}
                >
                  {steps.map((step, index) => (
                    <SwiperSlide key={index}>
                      <div className='text-center'>
                        <img
                          src={step.image}
                          className={`${styles.imgOnboarding} ${
                            index === 1 ? styles.imgTwoStyles : ''
                          } ${index === 2 ? styles.imgThreeStyles : ''}`}
                          alt={`step-${index + 1}`}
                        />
                      </div>
                      <div className={`${styles.flexFiles}`}>
                        <article className={`${styles.title} title`}>{step.title}</article>
                        <article className={`${styles.textDescription} text-description`}>
                          {step.subtitle}
                        </article>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>

            <div
              className='position-fixed w-100'
              style={{
                bottom: 0,
                left: 0,
                paddingBottom: 'env(safe-area-inset-bottom)',
                backgroundColor: '#000',
                zIndex: 2,
              }}
            >
              <div className={styles.carouselDots}>
                {steps.map((step, index) => (
                  <span
                    key={index}
                    className={`${styles.dot} ${index === currentStep ? styles.active : ''}`}
                    onClick={() => handleDotClick(index)}
                  ></span>
                ))}
              </div>
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-12 col-md-7 col-lg-6'>
                    {currentStep === steps.length - 1 ? (
                      <Button
                        className='mb-3'
                        size='large'
                        text={t('Entendido')}
                        onClick={() => setOpenModal(true)}
                      />
                    ) : (
                      <Button
                        className='mb-3'
                        size='large'
                        text={t('Siguiente')}
                        onClick={() => {
                          if (swiperRef.current) {
                            swiperRef.current.swiper.slideNext();
                            setCurrentStep(swiperRef.current.swiper.activeIndex);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ContentApp>
        </>
      )}
    </>
  );
};

export default OnboardingCampaigns;
