import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WelletContext } from '../../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Common/Header/Header';
import SuccessScreen from '../../Common/SuccessScreen';
import Button from '../../../components/Common/Button/Button';
import { getPaymentMethod } from '../../../actions/associatesActions';
import StripeIcon from '../../../components/icons/stripe';
import styles from './stripe.module.css';
import SuccessCheck from '../../../components/icons/SuccessCheck';

const LinkStripeConnect = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const dispatch = useDispatch();
  const [onboardingEmail, setOnboardingEmail] = useState(null);
  const [nextPage, setnextPage] = useState(false);
  const [withdrawalMethod, setWithdrawalMethod] = useState(null);

  const sendOnboardingLink = async () => {
    try {
      setnextPage(true);

      const response = await welletContext.apis.tickets.post('/withdrawal/stripe/OnboardingEmail');
      setnextPage(false);
      setOnboardingEmail(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const refreshWithdrawalMethod = async () => {
    try {
      const withdrawalMethodResult = await getPaymentMethod(welletContext);
      dispatch({
        type: 'USER_WITHDRAWAL',
        payload: withdrawalMethodResult.data,
      });
      setWithdrawalMethod(withdrawalMethodResult.data);
    } catch (error) {
      setWithdrawalMethod('none');
    }
  };

  useEffect(() => {
    refreshWithdrawalMethod();
  }, []);

  const renderBody = () => {
    if (withdrawalMethod === null) {
      return null;
    }

    if (nextPage) {
      return (
        <div className={styles.container}>
          <Header title={t('stripe.emailSent')} />

          <div className={styles.logo}>
            <SuccessCheck color='var(--color-primary)' size={200} />
          </div>
          <div className={styles.instructionsTitleCheck}>{t('stripe.ready')}</div>
          <div className={styles.instructionsSubTitleCheck}>{t('stripe.sendTheInstructions')}</div>
          <div className={styles.button}>
            <Button
              text={t('entendido')}
              size='large'
              typeIcon='arrow'
              color='#000'
              className='btn-primary'
              onClick={() => history.goBack()}
              disabled={false}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles.container}>
        <Header title={t('stripe.receivePayments')} />
        <div className={styles.logo}>
          <StripeIcon size='102px' />
        </div>
        <div className={styles.instructionsTitle}>
          {t('stripe.ReceivePaymentsIntoYourBankAccount')}
        </div>
        <div className={styles.instructionsSubTitle}>{t('stripe.startReceivingPayments')}</div>
        <div className={styles.button}>
          <Button
            text={t('stripe.requestInstructions')}
            size='large'
            icon
            typeIcon='arrow'
            color='#000'
            className='btn-primary'
            iconColor='#000'
            onClick={() => sendOnboardingLink()}
            disabled={false}
          />
        </div>
      </div>
    );
  };

  if (onboardingEmail) {
    return (
      <SuccessScreen
        title={`${t('done')}!`}
        subtitle={`Se ha enviado un email a ${onboardingEmail} con un enlace para completar el proceso de vinculación de tu cuenta Stripe. Una vez finalizado el proceso, haz click en Continuar.`}
        onClick={async () => {
          await refreshWithdrawalMethod();
          history.push('/');
        }}
      />
    );
  }

  if (withdrawalMethod !== null && withdrawalMethod !== 'none') {
    return (
      <SuccessScreen
        title='Se ha vinculado tu cuenta de Stripe exitosamente'
        subtitle={`Hemos vinculado tu cuenta de Stripe exitosamente.`}
        path='/'
      />
    );
  }

  return <>{renderBody()}</>;
};

export default LinkStripeConnect;
