import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './LinkQr.module.css';
import { useTranslation } from 'react-i18next';
import { fetchShow } from '../../../actions/appActions';
import { WelletContext } from '../../../context/WelletContext';
import { getCookie } from '../../../helpers/cookie';
import { skeletonRestaurantCard, skeletonRestaurantHeader } from '../../../components/Skeletons/SkeletonRestaurantSelector';
import DataManager from '../../../context/DataManager';
import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import SolidChevron from '../../../components/icons/SolidChevron';
import CustomerNav from '../../../components/CustomerNav/CustomerNav';
import ContentApp from '../../../components/ContentApp';
import RestaurantCard from '../../../components/RestaurantCard/RestaurantCard';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const LinkQrRestaurantList = () => {
  const { t } = useTranslation();
  const { citySelected } = useContext(DataManager);
  const cityCookie = getCookie('selectedCity');
  const userCity = useSelector((state) => state.associates.user.company.country);
  const observer = useRef();
  const pageRef = useRef(1);
  const welletContext = useContext(WelletContext);
  const currency = useSelector((state) => state.currency.default);
  const app = useSelector((state) => state.app);
  const [restaurants, setRestaurants] = useState(null);
  const [loadMoreRestaurants, setLoadMoreRestaurants] = useState(false);
  const [loadingRestaurants, setLoadingRestaurants] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [cities, setCities] = useState(null);
  const [city, setCity] = useState(null);
  const history = useHistory();

const location = useLocation();
  useEffect(() => {
    if (!cities) {
      getCities();
    }
  }, []);

  useEffect(() => {
    if (!restaurants && city) {
      getRestaurants(city.id);
    }
  }, [city]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getRestaurants = async (selectedCity, page = 1, pageSize = 15) => {
    setLoadingRestaurants(true);
    try {
      const result = await fetchShow(app.language, selectedCity, page, pageSize, welletContext);
      setRestaurants((prevData) => {
        if (prevData) {
          return [...prevData, ...result.shows];
        } else {
          return result.shows;
        }
      });
      setHasMore(result.shows.length >= pageSize);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRestaurants(false);
      setLoadMoreRestaurants(false);
    }
  };

  const getCities = async () => {
    try {
      const response = await welletContext.apis.tickets.get('/shows/cities');
      setCities(response.data);
      const defaultCity = response.data[0];
      if (citySelected) {
        setCity(response.data.find((c) => c.id === citySelected));
      } else if (cityCookie !== null && cityCookie?.trim() !== '') {
        const cookieCity = response.data.find((c) => c.id === Number(cityCookie));
        setCity(cookieCity ? cookieCity : defaultCity);
      } else {
        setCity(defaultCity);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleCitySelectorClick = () => {
    history.push({
      pathname: '/city-selector',
      state: { from: location.pathname }
    });
  };
  const loadMore = () => {
    setLoadMoreRestaurants(true);
    pageRef.current += 1;
    getRestaurants(city?.id, pageRef.current);
  };

  const onRestaurantClick = (show) => {
    history.push({
      pathname: show.reservationTypes.length >= 0 ? '/qr-restaurant' : '/link-qr',
      search: `?showId=${show.id}&showName=${show.name}`,
      state: { name: show.name },
    });
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingRestaurants) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore],
  );

  return (
    <>
      <AccountHeader
        title={<div className='text-capitalize'>{t('book')}</div>}
        icon='config'
        pathName='/configuration'
      />
      <ContentApp menu header>
        {loadingRestaurants && !restaurants ? (
          skeletonRestaurantHeader()
        ) : (
          <div className={`${styles.highlight} fade-up`}>
            <div
              className={
                userCity === 'US' || userCity === 'ES'
                  ? 'd-none'
                  : `d-flex mx-auto justify-content-center bold text-white align-items-center font-medium ${styles.cityContainer}`
              }
              style={{ width: city?.name?.length > 6 ? '70%' : '50%' }}
              onClick={handleCitySelectorClick}
            >
              {city?.name}

              <SolidChevron size='15' color='var(--color-primary)' className='ml-2' />
            </div>
            <div className='mt-4'>
              {restaurants?.length > 0 ? (
                restaurants.map((r, index) => (
                  <RestaurantCard
                    key={index}
                    lastElementRef={lastElementRef}
                    index={index}
                    restaurant={r}
                    onRestaurantClick={() => onRestaurantClick(r)}
                  />
                ))
              ) : !loadingRestaurants && restaurants?.length === 0 ? (
                <div className='mt-3'>
                  <EmptyActivity
                    title='Lo sentimos'
                    subtitle='En este momento no hay locales disponibles'
                  />
                </div>
              ) : (
                skeletonRestaurantCard()
              )}
              {loadMoreRestaurants && <div>{skeletonRestaurantCard()}</div>}
            </div>
          </div>
        )}
        <CustomerNav history={history} />
      </ContentApp>
    </>
  );
};
export default LinkQrRestaurantList;
