import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getQueryString } from '../../helpers/url';
import { WelletContext } from '../../context/WelletContext';
import { useDispatch, useSelector } from 'react-redux';
import { errorText } from '../../helpers/login';
import { useTranslation } from 'react-i18next';
import { setCanRefresh } from '../../actions/appActions';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import useForm from '../../helpers/useForm';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import IndustryRoleSelector from '../../components/Common/IndustryRoleSlector/IndustryRoleSelector';
import { Spinner } from '../../components/Common/Spinner/Spinner';
import 'react-phone-number-input/style.css';
import InputFormBorder from '../../components/Common/InputFormBorder/InputFormBorder';

const SignUpPhoneValidationScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const phone = getQueryString('phone');
  const token = getQueryString('token');
  const countryCode = getQueryString('countryCode');
  const welletContext = useContext(WelletContext);
  const tenantId = useSelector((state) => state.app.tenantId);
  const dispatch = useDispatch();
  const [verifyingUser, setVerifyingUser] = useState(false);
  const [errorUser, setErrorUser] = useState(undefined);
  const [selectedIndustry, setSelectedIndustry] = useState(t('signUp.selectSector'));
  const [selectedRp, setSelectedRp] = useState({ label: t('signUp.selectRp') });
  const [selectedCity, setSelectedCity] = useState({ label: t('signUp.selectCity') });
  const hasNoRp = selectedRp.value === '0';
  const requiresRP = useSelector((state) => state.app.requiresRP);

  useEffect(() => {
    dispatch(setCanRefresh(false));
  }, []);

  function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = t('emailError.EMPTY_EMAIL');
    } else if (!values.email.includes('@')) {
      errors.email = t('emailError.EMAIL_@');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = t('emailError.INVALID_EMAIL');
    }
    if (values.email && !values.emailConfirmation) {
      errors.emailConfirmation = t('emailError.EMPTY_EMAIL');
    } else if (values.email !== values.emailConfirmation) {
      errors.emailConfirmation = t('emailError.EMAIL_NOT_MATCH');
    }
    if (!values.industry || values.industry === t('selectSector')) {
      errors.industry = t('errorSignUp.NO_INDUSTRY');
    }
    if (!values.role) {
      errors.role = t('errorSignUp.NO_ROLE');
    }
    if (!values.company) {
      errors.company = t('errorSignUp.NO_COMPANY');
    }
    return errors;
  }

  const sendForm = async () => {
    setVerifyingUser(true);
    try {
      const result = await welletContext.apis.tickets.post(`users/Available`, {
        email: values.email,
        tenantId: tenantId,
      });

      if (result.data.isAvailable) {
        errorText(result.data.availableMessage, setErrorUser, t);
      } else {
        let query =
          '?phone=' +
          phone +
          '&countryCode=' +
          countryCode +
          '&token=' +
          token +
          '&email=' +
          values.email +
          '&industry=' +
          values.industry +
          '&role=' +
          values.role +
          '&company=' +
          values.company;

        if (requiresRP) {
          query += '&rp=' + values.rp + '&city=' + values.city;
        }

        history.push({
          pathname: '/sign-up-create-password',
          search: query,
        });
      }

      setVerifyingUser(false);
    } catch (error) {
      console.error(error);
      setVerifyingUser(false);
    }
  };

  const onChange = (e) => {
    setErrorUser('');
    handleChange(e);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  return (
    <>
      <Header />
      <ContentApp background={true}>
        <div className=''>
          <div className='font-medium bold text-white' style={{ lineHeight: '1.6rem' }}>
            {t('enterEmail')}
          </div>
          <div className='mt-2 text-secondary subtitle' style={{ lineHeight: '1.3rem' }}>
            {t('signUp.subtitle3')}
          </div>
          <form onSubmit={handleSubmit}>
            <div className='mt-4 font-normal'>
              <span className='text-secondary bold text-capitalize'>{t('email')}</span>
              <InputFormBorder
                name='email'
                type='email'
                value={values.email || ''}
                error={errors.email}
                placeholder='E-mail'
                onChange={onChange}
                forceLowecase={true}
                customClass='mt-2'
              />
            </div>
            <div className='mt-4'>
              <span className='text-secondary bold text-capitalize'>
                {t('signUp.confirmEmail')}
              </span>
              <InputFormBorder
                name='emailConfirmation'
                type='email'
                customClass='mt-2'
                value={values.emailConfirmation || ''}
                error={errors.emailConfirmation}
                placeholder={t('signUp.confirmEmail')}
                onChange={onChange}
                forceLowecase={true}
              />
            </div>
            <div className='mt-4'>
              <IndustryRoleSelector
                handleChange={onChange}
                selectedIndustry={selectedIndustry}
                setSelectedIndustry={setSelectedIndustry}
                selectedRp={selectedRp}
                setSelectedRp={setSelectedRp}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                values={values}
                errors={errors}
                requiresRP={requiresRP}
              />
            </div>
            {errorUser && (
              <div className='mt-5'>
                <ErrorBox title={errorUser} />
              </div>
            )}
            <div className='mt-4'>
              <Button
                size='large'
                fontSize={'18px'}
                text={
                  verifyingUser ? (
                    <div className='d-flex justify-content-center'>
                      <Spinner size={25} color='#000' />
                    </div>
                  ) : (
                    t('continue')
                  )
                }
                icon={verifyingUser ? false : true}
                typeIcon='arrow'
                iconColor='#000'
              />
            </div>
          </form>
        </div>
      </ContentApp>
    </>
  );
};

export default SignUpPhoneValidationScreen;
