import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductCard from '../ProductCard/ProductCard';
import SkeletonReservationArea from '../Skeletons/SkeletonReservationArea';
import { useSelector } from 'react-redux';

const ReservationTickets = ({
  products,
  handleIncrement,
  handleDecrement,
  setSelectedProduct,
  toggleModal,
  loading,
}) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.currency);

  const onInfoClick = (id) => {
    setSelectedProduct(id);
    toggleModal();
  };

  return (
    <div>
      {loading ? (
        SkeletonReservationArea()
      ) : (
        <>
          {products.map((p, index) => {
            return (
              <ProductCard
                key={index}
                product={p}
                handleIncrement={() => handleIncrement(index)}
                handleDecrement={() => handleDecrement(index)}
                hasMoreInfo={true}
                onInfoClick={() => {
                  onInfoClick(p);
                }}
              />
            );
          })}
        </>
      )}

      <div className='col my-auto font-smaller pr-0'>
        {t('priceIn', { currency: currency.default.shortForm })} ({currency.default.name})
      </div>
    </div>
  );
};

export default ReservationTickets;
