import React from 'react';



const CopyIcon = ({ size, fillColor }) => {
  return (
    <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.0'
    width={size}
    height={size}
    viewBox='0 0 64.000000 50.000000'
    preserveAspectRatio='xMidYMid meet'
  >
    <g
      transform='translate(0.000000,50.000000) scale(0.100000,-0.100000)'
      fill={fillColor}
      stroke='none'
    >
      <path d='M223 440 c-12 -10 -24 -28 -28 -39 -3 -11 -15 -23 -26 -26 -11 -4 -29 -16 -39 -28 -18 -18 -20 -35 -20 -139 0 -113 1 -120 25 -143 23 -24 30 -25 143 -25 105 0 121 2 140 20 12 12 22 25 22 29 0 5 11 12 25 15 13 3 34 16 45 28 18 20 20 35 20 150 0 124 -1 130 -25 153 -23 24 -30 25 -143 25 -104 0 -121 -2 -139 -20z m257 -160 c0 -109 -2 -130 -15 -130 -12 0 -15 16 -15 86 0 81 -1 87 -29 115 -27 27 -35 29 -105 29 -61 0 -76 3 -76 15 0 13 20 15 120 15 l120 0 0 -130z m-82 -72 l3 -118 -121 0 -120 0 0 120 0 121 118 -3 117 -3 3 -117z' />
    </g>
  </svg>
  );
};

export default CopyIcon;
