import React from 'react';

const QrIconHome = ({ size = 24, color = '#000' }) => {
  return (
    <svg id="b" data-name="Capa_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.99 18.99" width={size} height={size} fill={color}>
      <g id="c" data-name="Capa_1">
        <g>
          <path d="M18.99,5.96c-.48,0-.96-.01-1.45-.02-1.41,0-2.83,0-4.24,0-.17,0-.26-.02-.26-.23,0-1.85,0-3.7,0-5.55,0-.04,0-.09.01-.16h5.93v5.96ZM14.26,4.73h3.53V1.2h-3.53v3.53Z"/>
          <path d="M0,18.99v-5.94h.3c1.78,0,3.56,0,5.34,0,.25,0,.31.07.31.32,0,1.77,0,3.54,0,5.31,0,.11.01.21.02.32H0ZM1.21,14.26v3.53h3.52v-3.53H1.21Z"/>
          <path d="M7.14,18.99v-1.18h2.34v-3.56h-2.34v-1.18h3.54v3.55h2.36v1.18h-2.36v1.2h-3.54Z"/>
          <path d="M18.99,8.32h-1.18v1.17h-4.75v-1.16h1.17v-1.18h1.19v1.16h1.18v-1.16h2.38v1.18Z"/>
          <path d="M14.22,18.99c0-.22.02-.44.03-.67,0-.86,0-1.71,0-2.57v-.31h3.55v1.17h-2.34v2.37h-1.23Z"/>
          <path d="M18.99,11.86h-2.35v-1.18h2.35v1.18Z"/>
          <path d="M16.63,18.99v-1.18h2.36v1.18h-2.36Z"/>
          <path d="M18.99,14.27h-1.16v-1.23h1.16v1.23Z"/>
          <path d="M0,5.92V.02h5.93c0,.08,0,.17,0,.25,0,1.8,0,3.59,0,5.39,0,.16.01.29-.23.29-1.86,0-3.72,0-5.57,0-.03,0-.07,0-.13-.02ZM4.73,1.21H1.2v3.52h3.53V1.21Z"/>
          <path d="M4.76,11.85h-1.18v-2.35h1.16v-2.36h1.19v2.34h2.37v1.19h1.18v1.18h-2.34v-1.15h-2.38v1.15Z"/>
          <path d="M8.3,2.37h3.55v1.19h-1.16v1.17h-1.18v-1.16h-2.36V1.2h1.15v1.17Z"/>
          <path d="M7.14,8.3v-1.16h3.53v-1.17h1.18v2.33h-4.71Z"/>
          <path d="M.02,7.14h1.16v2.35h1.18v1.18h-1.16v1.17H.02v-4.71Z"/>
          <path d="M15.41,14.23h-1.16v-2.35h-1.19v-1.18h2.34v3.53Z"/>
          <path d="M11.89,13.07h1.15v2.34h-1.15v-2.34Z"/>
          <path d="M9.52,0h2.33v1.16h-2.33V0Z"/>
          <path d="M11.85,11.86h-1.15v-2.34h1.15v2.34Z"/>
          <path d="M7.15,4.76h1.16v1.15h-1.16v-1.15Z"/>
          <path d="M2.39,7.14h1.14v1.16h-1.14v-1.16Z"/>
          <path d="M8.3,16.61h-1.15v-1.16h1.15v1.16Z"/>
          <path d="M16.61,3.54h-1.15v-1.15h1.15v1.15Z"/>
          <path d="M2.38,16.61v-1.15h1.16v1.15h-1.16Z"/>
          <path d="M2.38,3.54v-1.15h1.15v1.15h-1.15Z"/>
        </g>
      </g>
    </svg>
  );
};

export default QrIconHome;
