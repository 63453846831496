import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WelletContext } from '../../../context/WelletContext';
import { useActivities } from '../../../hooks/useActivities';
import { format, parseISO } from 'date-fns';
import { formatDateLong, getDateFromOffset, getLocale } from '../../../helpers/dates';
import { useTranslation } from 'react-i18next';
import {
  SkeletonEarningsNotPaid,
  SkeletonEmptyActivity,
  SkeletonMPAccount,
} from '../../../components/Skeletons/SkeletonMyMoney';
import Balance from '../../../components/Balance/Balance';
import ContentApp from '../../../components/ContentApp';
import Activity from '../../../components/Activities/Activity/Activity';
import Money from '../../../components/Common/Money/Money';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import mpPago from '../../../images/mp-logo.png';
import EarningsNotPaid from '../../../components/Payments/EarningsNotPaid';
import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import CustomerNav from '../../../components/CustomerNav/CustomerNav';
import Button from '../../../components/Common/Button/Button';
import CashIcon from '../../../components/icons/CashIcon';
import styles from './payments.module.css';
import LockIcon from '../../../components/icons/LockIcon';
import { getAssociateSaleSetup } from '../../../actions/associatesActions';
import BankIcon from '../../../components/icons/BankIcon';
import CertificateIcon from '../../../components/icons/CertificateIcon';
import ActivitiesHome from '../../../components/Activities/ActivitiesHome/ActivitiesHome';

const Payments = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);
  const welletContext = useContext(WelletContext);
  const associates = useSelector((state) => state.associates);
  const user = useSelector((state) => state.associates.user);
  const today = format(new Date(getDateFromOffset(user.companyUtcMinutesOffset)), 'yyyy-MM-dd');
  const [earningsNotPaid, setEarningsNotPaid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [summary, setSummary] = useState(null);
  const [selectedDate, setSelectedDate] = useState(today);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const kycStatus = useSelector((state) => state.associates.user.kycStatus);

  const wireOrMp =
    user.withdrawalMethod === 'WIRE_INTERNATIONAL' ||
    user.withdrawalMethod === 'WIRE_MEXICO' ||
    user.withdrawalMethod === 'MERCADOPAGO';
  const cashOrCertificate =
    user.withdrawalMethod === 'CASH' || user.withdrawalMethod === 'CERTIFICATE';

  const withdrawalsInfoData = useActivities({
    pageSize: 5,
    endpoint: '/associates/GetAccreditedPayments',
    api: welletContext.apis.tickets,
    isReservation: true,
  });

  const pendingCashPayments = useActivities({
    pageSize: 5,
    endpoint: '/associates/GetCashPendingPayments',
    api: welletContext.apis.tickets,
    isReservation: true,
  });

  const pendingCertificatePayments = useActivities({
    pageSize: 5,
    endpoint: '/associates/GetCertificatePendingPayments',
    api: welletContext.apis.tickets,
    isReservation: true,
  });

  useEffect(() => {
    if (user.kycStatus !== 'APPROVED') {
      dispatch(getAssociateSaleSetup(associates.jwt));
    }
  }, []);

  const getEarningsNotPaid = async () => {
    setLoading(true);
    try {
      const earningsNotPaid = await welletContext.apis.tickets.get(
        `/associates/GetEarningsNotPaid`,
      );
      setEarningsNotPaid(earningsNotPaid.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getPaymentMethod = async () => {
    try {
      const result = await welletContext.apis.tickets.get('/withdrawal/GetOneUserWithdrawal');
      if (result.status === 200) {
        setPaymentMethod(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBalanceSummary = async (date = selectedDate, includeDetails = true) => {
    setLoadingSummary(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${summary ? date : today}&to=${
          summary ? date : today
        }&includeDetails=${includeDetails}`,
      );
      setSummary(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingSummary(false);
    }
  };

  const handleChangeDate = (day) => {
    if (format(day, 'yyyy-MM-dd') === selectedDate) {
      setSelectedDate(selectedDate);
    } else {
      setSelectedDate(day);
    }
    setCalendarVisible(!calendarVisible);
    getBalanceSummary(format(day, 'yyyy-MM-dd'), true);
  };

  useEffect(() => {
    getEarningsNotPaid();
    getPaymentMethod();
    getBalanceSummary(selectedDate, true);
  }, []);

  const renderPendingPayments = (pendingPayments) => {
    let buttonText = '';
    let withdrawUrl = '';
    if (pendingPayments?.activities?.paymentMethod === 'CASH') {
      buttonText = t('withdrawCashPayment');
      withdrawUrl = '/withdrawal-cash';
    } else if (pendingPayments?.activities?.paymentMethod === 'CERTIFICATE') {
      buttonText = t('withdrawCertificatePayment');
      withdrawUrl = '/withdrawal-certificate';
    } else {
      return null;
    }

    return (
      <div className='mt-2'>
        {!loading ? (
          <ActivitiesHome
            title={<div className='bold'>{t('pendingCollections')}</div>}
            linkTitle={
              pendingPayments?.activities?.payments?.length === 5 && kycStatus !== 'APPROVED'
                ? t('allPendingCollections')
                : null
            }
            pathName={'/pending-withdrawals'}
            history={history}
            children={
              <div className='p-2'>
                {pendingPayments?.activities?.payments.map((p, index) => (
                  <Activity
                    key={index}
                    loading={pendingPayments.loading}
                    hasMore={pendingPayments.hasMore}
                    loadMore={pendingPayments.loadMore}
                    item1={formatDateLong(p.createdAt, locale)}
                    item2={
                      <Money value={p.amount} decimal={false} lowDecimals symbolSize='.85rem' />
                    }
                  />
                ))}
              </div>
            }
            activityFooter={
              pendingPayments?.activities?.totalAmount ? (
                <>
                  <div className='card-bottom'></div>
                  <div className='d-flex justify-content-between p-3'>
                    <div>{t('totalPendingCollections')}</div>
                    <div className='bold'>
                      <Money
                        value={pendingPayments?.activities?.totalAmount}
                        decimal={false}
                        lowDecimals
                        symbolSize='.85rem'
                      />
                    </div>
                  </div>
                  <div className='mt-1 px-3 pb-3'>
                    <Button
                      size='large'
                      text={buttonText}
                      className='btn-primary'
                      onClick={() => history.push(withdrawUrl)}
                      disabled={kycStatus !== 'APPROVED'}
                    />
                  </div>
                </>
              ) : null
            }
          />
        ) : (
          SkeletonEmptyActivity()
        )}
      </div>
    );
  };

  const renderLastPayments = () => {
    return (
      <div className='mt-2'>
        {!loading ? (
          <ActivitiesHome
            title={<div className='bold'>{t('recentPayments')}</div>}
            linkTitle={withdrawalsInfoData?.activities?.length === 5 ? t('allPayments') : null}
            pathName={'/all-payments'}
            history={history}
            children={
              withdrawalsInfoData?.activities?.length === 0 ? (
                <EmptyActivity
                  title={t('noPayments')}
                  subtitle={t('noPaymentsDescription')}
                  styles={{ backgroundColor: 'rgba(30, 30, 30, 0.7)' }}
                />
              ) : (
                <div className='p-2'>
                  {withdrawalsInfoData?.activities?.map((w, index) => (
                    <Activity
                      key={index}
                      loading={withdrawalsInfoData.loading}
                      hasMore={withdrawalsInfoData.hasMore}
                      loadMore={withdrawalsInfoData.loadMore}
                      item1={format(parseISO(w.updatedAt), "d MMMM 'de' yyyy", {
                        locale,
                      })}
                      item2={
                        <Money value={w.amount} decimal={false} lowDecimals symbolSize='.85rem' />
                      }
                    />
                  ))}
                </div>
              )
            }
          />
        ) : (
          SkeletonEmptyActivity()
        )}
      </div>
    );
  };
  const renderPaymentMethod = (method, additionalData) => {
    let iconComponent;
    let paymentMethodText;
    let content;

    switch (method) {
      case 'CASH':
        iconComponent = <CashIcon size='25px' />;
        paymentMethodText = t('cash');
        break;
      case 'CERTIFICATE':
        iconComponent = <CertificateIcon color='#fff' size='25px' />;
        paymentMethodText = t('consumptionCertificate');
        break;
      case 'WIRE_MEXICO':
      case 'WIRE_INTERNATIONAL':
        iconComponent = <BankIcon size='25px' color='#fff' />;
        paymentMethodText =
          method === 'WIRE_MEXICO' ? t('WIRE.nationalWire') : t('WIRE.internationalWire');
        break;
      case 'MERCADOPAGO':
        if (additionalData.email) {
          iconComponent = <img src={mpPago} alt='mp' className='p-1' />;
          content = (
            <div>
              <div className='font-tiny text-secondary'>{t('mercadoPagoText')}</div>
              <div className='text-truncate' style={{ maxWidth: '230px' }}>
                {additionalData.email}
              </div>
            </div>
          );
        } else {
          content = (
            <div className='ml-3 my-3 text-secondary text-center font-small fade-up'>
              {t('noMercadoPagoAccount')}
            </div>
          );
        }
        break;
      default:
        return null;
    }

    if (method === 'MERCADOPAGO') {
      return (
        <div className='row mx-2 my-3 align-items-center fade-up'>
          <div className='col-auto'>{iconComponent}</div>
          <div className='col pl-0'>{content}</div>
        </div>
      );
    } else {
      return (
        <div className='row mx-2 my-3 align-items-center fade-up'>
          <div className='col-auto'>{iconComponent}</div>
          <div className='col pl-0'>
            <div className='text-secondary'>{paymentMethodText}</div>
            <div className='text-truncate' style={{ maxWidth: '230px' }}>
              {additionalData.bank}
            </div>
            <div>{additionalData.clabe?.slice(-4) ?? additionalData.swift?.slice(-4)}</div>
          </div>
        </div>
      );
    }
  };

  const hasPendingCashPayment =
    pendingCashPayments?.activities?.payments.length > 0 &&
    paymentMethod?.withdrawalMethod?.methodTypeString !== 'MERCADOPAGO';

  const hasPendingCertificatePayment =
    pendingCertificatePayments?.activities?.payments.length > 0 &&
    paymentMethod?.withdrawalMethod?.methodTypeString !== 'MERCADOPAGO';

  const redirectPath = () => {
    const methodType = paymentMethod?.withdrawalMethod?.methodType;
    switch (methodType) {
      case 'MERCADOPAGO':
        return '/mercadopagoaccount';
      case 'WIRE_MEXICO':
        return '/national-bank';
      case 'WIRE_INTERNATIONAL':
        return '/international-bank';
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (!loading) {
      const methodType = user.withdrawalMethod;
      if (methodType) {
        const additionalData = {
          bank: paymentMethod.bank,
          clabe: paymentMethod.clabe,
          swift: paymentMethod.swift,
          email: paymentMethod.email,
        };
        return renderPaymentMethod(methodType, additionalData);
      } else {
        return null;
      }
    } else {
      return <SkeletonMPAccount />;
    }
  };

  return (
    <>
      <AccountHeader title={t('payments')} icon='config' pathName='/configuration' />
      <ContentApp header menu classes='position-relative'>
        <div
          className={
            kycStatus !== 'APPROVED' && !loading ? `${styles.censuredText} fade-up` : 'd-none'
          }
        >
          <LockIcon size='50px' color='#fff' />
          <div className='bold text-white mt-4 text-center'>
            {t('identityNotValidated.paymentsText')}
          </div>
        </div>
        <div className={kycStatus !== 'APPROVED' ? styles.censuredContent : ''}>
          <div className='my-money p-0'>
            <Balance
              title={t('earnings')}
              fontSize='1.8rem'
              showText={false}
              loading={loading}
              history={history}
              mpVerified={loading || paymentMethod !== 0}
              summary={summary}
              handleChangeDate={handleChangeDate}
              loadingSummary={loadingSummary}
              selectedDate={selectedDate}
              setCalendarVisible={setCalendarVisible}
              calendarVisible={calendarVisible}
              todayBalance={summary?.amount}
            />
            {!loading ? (
              <div className='earnings-not-paid'>
                <EarningsNotPaid amount={earningsNotPaid?.amount} />
              </div>
            ) : (
              <div className='mt-2'>{SkeletonEarningsNotPaid()}</div>
            )}
            {hasPendingCashPayment ? renderPendingPayments(pendingCashPayments) : null}
            {hasPendingCertificatePayment
              ? renderPendingPayments(pendingCertificatePayments)
              : null}
            {renderLastPayments()}
            {!loading && (cashOrCertificate || (wireOrMp && user.userWithdrawalMethod)) ? (
              <div className='mt-2'>
                <ActivitiesHome
                  history={history}
                  title={<div className='bold'>{t('paymentMethod')}</div>}
                  pathName={redirectPath()}
                  children={renderContent()}
                  linkTitle={wireOrMp && user.userWithdrawalMethod ? t('modifyAccount') : null}
                />
              </div>
            ) : null}
          </div>
        </div>
        <CustomerNav history={history} />
      </ContentApp>
    </>
  );
};

export default Payments;
