import { useEffect, useContext, useCallback, useState } from 'react';
import AssociateRealtime from '../context/AssociateRealtimeProvider';
import axios from 'axios';
import { WelletContext } from '../context/WelletContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAssociateSaleSetup } from '../actions/associatesActions';

const useRealtimeReservations = (today, filterDate, onValidReservationUpdate) => {
  const { connection } = useContext(AssociateRealtime);
  const dispatch = useDispatch();

  const welletContext = useContext(WelletContext);
  const [loading, setLoading] = useState(true);
  const [reservations, setReservations] = useState([]);
  const [newReservation, setNewReservation] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const jwt = useSelector((state) => state.associates.jwt);

  const fetchReservations = async (silent = false) => {
    setLoading(true);
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Cancelled due to new request');
    }
    if (!silent) setLoading(true);

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);
    const body = {
      FilterDate: localStorage.getItem('filterDate') ?? filterDate,
      skip: 0,
      limit: 10,
    };
    try {
      const response = await welletContext.apis.tickets.post(`/reservation/LiveFeed`, body, {
        cancelToken: newCancelTokenSource.token,
      });

      setReservations(response.data);
      setSkip(10);
      setHasMore(response.data.length === 10);
      setLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('failed to fetch', error.message);
        setLoading(true);
      }
    }
  };

  const fetchMoreReservations = async () => {
    if (loadingMore || !hasMore) return;
    setLoadingMore(true);

    const body = {
      FilterDate: localStorage.getItem('filterDate') ?? filterDate,
      skip,
      limit: 10,
    };

    try {
      const reservationsData = await welletContext.apis.tickets.post(`/reservation/LiveFeed`, body);
      const newReservations = reservationsData.data;
      if (newReservations.length > 0) {
        setReservations((prevReservations) => [...prevReservations, ...newReservations]);
        setSkip(skip + 10);
      }
      setHasMore(newReservations.length === 10);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMore(false);
    }
  };

  const onUpdateReservation = useCallback(
    (updatedReservation) => {
      setReservations((currentReservations) =>
        currentReservations.map((reservation) =>
          reservation.id === updatedReservation.id
            ? { ...reservation, ...updatedReservation }
            : reservation,
        ),
      );
      if (updatedReservation.amount !== 0) {
        onValidReservationUpdate();
      }
    },
    [onValidReservationUpdate],
  );

  const onRemoveReservation = useCallback((deletedReservationId) => {
    setReservations((currentReservations) =>
      currentReservations.filter((reservation) => reservation.id !== deletedReservationId),
    );
  }, []);

  const onNewReservation = useCallback(
    (newReservation) => {
      if (filterDate === 'RESERVATION_DATE') {
        const reservationDate = new Date(newReservation.reservationLocalTime);
        const todayDate = new Date(today);

        if (reservationDate >= today) {
          let updatedReservations = [...reservations];

          const endOfToday = new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate(),
            23,
            59,
            59,
          );
          const lastTodayIndex = updatedReservations.reduce((lastIndex, current, index) => {
            const currentDate = new Date(current.reservationLocalTime);
            return currentDate <= endOfToday ? index : lastIndex;
          }, -1);

          const limitIndex = lastTodayIndex + 6;
          const insertIndex = updatedReservations.findIndex(
            (res) => new Date(res.reservationLocalTime) > reservationDate,
          );

          if (
            (insertIndex >= 0 && insertIndex < limitIndex) ||
            (insertIndex === -1 && updatedReservations.length < limitIndex)
          ) {
            updatedReservations.splice(
              insertIndex >= 0 ? insertIndex : updatedReservations.length,
              0,
              newReservation,
            );
            setReservations(updatedReservations);
          } else {
            setNewReservation(newReservation);
          }
        }
      } else {
        if (reservations.some((reservation) => reservation.id === newReservation.id)) {
          setReservations(
            reservations.map((reservation) =>
              reservation.id === newReservation.id ? newReservation : reservation,
            ),
          );
        } else {
          setReservations([newReservation, ...reservations]);
        }
      }
    },
    [reservations],
  );

  useEffect(() => {
    fetchReservations();
  }, [filterDate]);

  useEffect(() => {
    if (connection && reservations) {
      connection.on('UpdateReservation', onUpdateReservation);
      connection.on('NewReservation', onNewReservation);
      connection.on('RemoveReservation', onRemoveReservation);
      connection.on('ChangeAdvantageNetwork', () => {
        dispatch(getAssociateSaleSetup(jwt));
      });
    }

    return () => {
      if (connection) {
        connection.off('UpdateReservation', onUpdateReservation);
        connection.off('NewReservation', onNewReservation);
        connection.off('RemoveReservation', onRemoveReservation);
        connection.off('ChangeAdvantageNetwork');
      }
    };
  }, [reservations, onUpdateReservation, onNewReservation, onRemoveReservation]);

  return {
    reservations,
    loading,
    newReservation,
    loadingMore,
    hasMore,
    fetchMoreReservations,
  };
};

export default useRealtimeReservations;
