import React from 'react';
import styles from './CustomButton.module.css';

const CustomButton = ({
  text,
  leftIcon,
  rightIcon,
  size = 'instagram',
  onClick,
  className,
  disabled = false,
  width = null,
  color = '#000',
  backgroundColor = 'var(--color-primary)',
}) => {
  const sizeConfig = {
    instagram: {
      widthConfig: '100%',
      height: '5.5rem',
    },
    reserva: {
      widthConfig: '100%',
      height: '12rem',
    },
  };

  const { height, widthConfig } = sizeConfig[size];

  const buttonStyles = {
    width: widthConfig,
    height,
    color: color,
    backgroundColor: backgroundColor,
  };

  return (
    <button
      style={buttonStyles}
      className={`${styles.buttonCustom} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}

      {rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}

      <div className={styles.text}>{text}</div>
    </button>
  );
};

export default CustomButton;
